import { useState } from "react";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useDebouncedEffect } from "../hooks/useDebouncedEffect";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupsIcon from "@mui/icons-material/Groups";
import TextField from "@mui/material/TextField";

import { NewVideoCategoryDialog } from "../components/dialogs/NewVideoCategory";

import { AdminVideoResponse, Video, VideoCategory } from "../lib/definitions";
import {
  adminDeleteVideoCategories,
  adminDeleteVideos,
  adminGetVideoCategories,
  adminGetVideos,
} from "../lib/http";

import { NewVideoDialog } from "../components/dialogs/NewVideo";
import { AssignGroupDialog } from "../components/dialogs/AssignGroup";
import { AssignVideoCategoryDialog } from "../components/dialogs/AssignVideoCategory";
import { useURLQuery } from "../hooks/useQuery";

function useVideos(currentPage: number, searchTerm: string) {
  return useQuery<AdminVideoResponse, Error>(
    [
      "admin_videos",
      {
        page: currentPage,
        term: searchTerm,
      },
    ],
    () => {
      return adminGetVideos(currentPage, searchTerm);
    },
    {
      enabled: true,
    }
  );
}

function useVideoCategories() {
  return useQuery<VideoCategory[], Error>(
    "admin_videocategories",
    () => {
      return adminGetVideoCategories();
    },
    {
      enabled: true,
    }
  );
}

const columns: GridColDef[] = [
  {
    field: "thumbnail",
    headerName: "Thumbnail",
    width: 200,
    sortable: false,
    renderCell: (params: GridRenderCellParams<Video>) => {
      return (
        <img
          style={{ width: "100%", height: "auto" }}
          src={params.row.thumbnail_url}
          alt="Testing"
        />
      );
    },
  },
  { field: "title", headerName: "Title", width: 350, sortable: false },
  { field: "views", headerName: "Views", width: 130, sortable: false },
  {
    field: "created_at",
    headerName: "Added At",
    width: 300,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${format(params.value as Date, "MM/dd/yyyy - hh:mmaa")}
  `,
  },
];

const categoryColumns = [
  { field: "name", headerName: "Category", width: 400, sortable: false },
  {
    field: "created_at",
    headerName: "Added At",
    width: 300,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${format(params.value as Date, "MM/dd/yyyy - hh:mmaa")}
      `,
  },
  {
    field: "updated_at",
    headerName: "Updated At",
    width: 300,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${
        params.value
          ? format(params.value as Date, "MM/dd/yyyy - hh:mmaa")
          : "Never"
      }
      `,
  },
];

type VideoTab = "videos" | "categories";

interface SearchFieldProps {
  label: string;
  onSearch: (term: string) => void;
}

export function AdminVideos() {
  const query = useURLQuery();

  const tab = query.get("tab");

  const [currentTab, setCurrentTab] = useState<VideoTab>(
    (tab as VideoTab) || "videos"
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: VideoTab) => {
    setCurrentTab(newValue);
  };

  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>Video Administration</h1>
        </div>
      </header>

      <div className="page_content">
        <div className="inner">
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Tabs value={currentTab} onChange={handleTabChange}>
              <Tab value="videos" label="Videos" />
              <Tab value="categories" label="Categories" />
            </Tabs>
          </Box>

          {currentTab === "videos" && <VideosTab />}
          {currentTab === "categories" && <CategoriesTab />}
        </div>
      </div>
    </main>
  );
}

function CategoriesTab() {
  const navigate = useNavigate();
  const [currentSelection, setCurrentSelection] = useState<string[]>([]);

  const [showNewCategoryDialog, setShowNewCategoryDialog] =
    useState<boolean>(false);

  const {
    isLoading: isCategoriesLoading,
    data: categories,
    refetch: refetchCategories,
  } = useVideoCategories();

  const onNewCategoryClick = () => {
    setShowNewCategoryDialog(true);
  };

  const onDeleteClick = async () => {
    const catConfirmation = window.confirm(
      `Are you sure you want to delete these ${currentSelection.length} categories?`
    );
    if (catConfirmation) {
      await adminDeleteVideoCategories(currentSelection);
      setCurrentSelection([]);
      refetchCategories();
    }
  };

  return (
    <div className="categoriesTab">
      <Grid container spacing={2} style={{ marginBottom: 30 }}>
        <Grid item xs={12}>
          <div className="toolbarContainer">
            <Fab
              color="primary"
              size="medium"
              variant="extended"
              onClick={onNewCategoryClick}
            >
              <AddIcon /> Add Category
            </Fab>
            <Fab
              color="default"
              size="small"
              disabled={currentSelection.length === 0}
              onClick={onDeleteClick}
            >
              <DeleteIcon />
            </Fab>
          </div>
        </Grid>
      </Grid>
      <div className="dataTableContainer">
        <DataGrid
          loading={isCategoriesLoading}
          rows={categories ? categories : []}
          columns={categoryColumns}
          pageSize={25}
          autoHeight={true}
          disableColumnMenu={true}
          paginationMode={"client"}
          rowsPerPageOptions={[25]}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={currentSelection}
          onSelectionModelChange={(model, details) => {
            setCurrentSelection(model as string[]);
          }}
          onRowDoubleClick={(row) => {
            navigate(`/admin/videos/category/${row.id}`);
          }}
        />
      </div>
      <NewVideoCategoryDialog
        open={showNewCategoryDialog}
        onClose={() => {
          setShowNewCategoryDialog(false);
          refetchCategories();
        }}
      />
    </div>
  );
}

function VideosTab() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentSelection, setCurrentSelection] = useState<string[]>([]);
  const [showNewVideoDialog, setShowNewVideoDialog] = useState<boolean>(false);
  const [showAssignGroupDialog, setShowAssignGroupDialog] =
    useState<boolean>(false);
  const [showAssignVideoCategoryDialog, setShowAssignVideoCategoryDialog] =
    useState<boolean>(false);

  const { isLoading, data, refetch } = useVideos(currentPage, searchTerm);

  const onNewVideoClick = () => {
    setShowNewVideoDialog(true);
  };

  const onAssignGroupClick = async () => {
    setShowAssignGroupDialog(true);
  };

  const onAssignVideoCategoryClick = async () => {
    setShowAssignVideoCategoryDialog(true);
  };

  const onDeleteClick = async () => {
    const videoConfirmation = window.confirm(
      `Are you sure you want to delete these ${currentSelection.length} videos?`
    );
    if (videoConfirmation) {
      await adminDeleteVideos(currentSelection);
      setCurrentSelection([]);
      refetch();
    }
  };

  return (
    <div className="videosTab">
      <Grid container spacing={2} style={{ marginBottom: 30 }}>
        <Grid item xs={4}>
          <Search
            label="Search for videos"
            onSearch={(term) => {
              setCurrentSelection([]);
              setCurrentPage(0);
              setSearchTerm(term);
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <div className="toolbarContainer">
            <Fab
              color="primary"
              size="medium"
              variant="extended"
              onClick={onNewVideoClick}
            >
              <AddIcon /> Add Video
            </Fab>
            <Fab
              color="default"
              size="medium"
              variant="extended"
              disabled={currentSelection.length === 0}
              onClick={onAssignGroupClick}
            >
              <GroupsIcon sx={{ mr: 1 }} /> Assign to Group
            </Fab>
            <Fab
              color="default"
              size="medium"
              variant="extended"
              disabled={currentSelection.length === 0}
              onClick={onAssignVideoCategoryClick}
            >
              <GroupsIcon sx={{ mr: 1 }} /> Assign to Category
            </Fab>

            <Fab
              color="default"
              size="small"
              disabled={currentSelection.length === 0}
              onClick={onDeleteClick}
            >
              <DeleteIcon />
            </Fab>
          </div>
        </Grid>
      </Grid>
      <div className="dataTableContainer">
        <DataGrid
          loading={isLoading}
          rows={data ? data.videos : []}
          columns={columns}
          pageSize={25}
          rowHeight={100}
          autoHeight={true}
          paginationMode={"server"}
          rowsPerPageOptions={[25]}
          disableColumnMenu={true}
          checkboxSelection
          disableSelectionOnClick
          rowCount={data ? data.total : 0}
          selectionModel={currentSelection}
          onRowDoubleClick={(row) => {
            navigate(`/admin/videos/${row.id}`);
          }}
          onPageChange={(page) => {
            setCurrentPage(page);
            refetch();
          }}
          onSelectionModelChange={(model, details) => {
            setCurrentSelection(model as string[]);
          }}
        />
      </div>

      <NewVideoDialog
        open={showNewVideoDialog}
        onClose={() => {
          setShowNewVideoDialog(false);
        }}
      />

      <AssignGroupDialog
        target="videos"
        selection={currentSelection}
        open={showAssignGroupDialog}
        onClose={(success) => {
          if (success) {
            setCurrentSelection([]);
            refetch();
          }
          setShowAssignGroupDialog(false);
        }}
      />

      <AssignVideoCategoryDialog
        selection={currentSelection}
        open={showAssignVideoCategoryDialog}
        onClose={(success) => {
          if (success) {
            setCurrentSelection([]);
            refetch();
          }
          setShowAssignVideoCategoryDialog(false);
        }}
      />
    </div>
  );
}

function Search({ label, onSearch }: SearchFieldProps) {
  const [value, setValue] = useState<string>("");

  useDebouncedEffect(() => onSearch(value), [value], 600);

  return (
    <TextField
      fullWidth
      label={label}
      variant="standard"
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
      }}
    />
  );
}
