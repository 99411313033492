import * as yup from "yup";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { format } from "date-fns";
import { useSnackbar } from "notistack";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { useSingleUser } from "../hooks/useSingleUser";
import { useOrganizationGroup } from "../hooks/useOrganizationGroup";
import { useSingleUserAnalytics } from "../hooks/useSingleUserAnalytics";
import { useRoles } from "../hooks/useRoles";

import { Group, SingleUserAnalytics, User } from "../lib/definitions";
import { adminPostOrgUser, adminPutOrgUser } from "../lib/http";

import styles from "./AdminUserEdit.module.css";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const newUserValidationSchema = yup.object({
  email: yup.string().email().required("User email address is required."),
  first_name: yup.string().required("User first name is required."),
  last_name: yup.string().required("User last name is required."),
  password: yup.string().required("User must have a new password."),
  role_id: yup.string().required("A user role is required."),
});

const validationSchema = yup.object({
  email: yup.string().email().required("User email address is required."),
  first_name: yup.string().required("User first name is required."),
  last_name: yup.string().required("User last name is required."),
  role_id: yup.string().required("A user role is required."),
});

interface UserInformationProps {
  user: User;
  groups: Group[];
}

interface UserAnalyticsProps {
  analytics: SingleUserAnalytics;
}

export function AdminUserEdit() {
  const params = useParams();
  const navigate = useNavigate();

  const { isLoading, data } = useSingleUser(params.userId ?? "");
  const { isLoading: analyticsLoading, data: analytics } =
    useSingleUserAnalytics(params.userId ?? "");

  const userGroups: Group[] = data
    ? Object.keys(data.groups).map((g) => {
        return {
          name: data.groups[g],
          id: g,
        };
      })
    : [];

  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>
            <IconButton size="large" onClick={() => navigate("/admin/users")}>
              <KeyboardBackspaceIcon fontSize="inherit" />
            </IconButton>
            User: {isLoading && <Skeleton variant="text" />}{" "}
            {!isLoading && data && (
              <>{`${data.first_name} ${data.last_name}`}</>
            )}
          </h1>
        </div>
      </header>

      <div className="page_content">
        <div className="inner">
          <div className={styles.userEditor}>
            {!isLoading && data && (
              <UserInformation user={data} groups={userGroups} />
            )}
            <div className="rcol">
              <h2>View Activity</h2>
              {!analyticsLoading && analytics && (
                <UserAnalytics analytics={analytics} />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

function UserAnalytics({ analytics }: UserAnalyticsProps) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Video</TableCell>
            <TableCell align="left">Viewed At</TableCell>
            <TableCell align="right">Started</TableCell>
            <TableCell align="right">Finished</TableCell>
            <TableCell align="right">% Viewed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {analytics.videos.length === 0 && (
            <TableRow>
              <TableCell colSpan={5}>No view history for this user.</TableCell>
            </TableRow>
          )}
          {analytics.videos.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.video}
              </TableCell>
              <TableCell align="right">
                {format(row.viewed_at as Date, "MM/dd/yyyy - hh:mmaa")}
              </TableCell>
              <TableCell align="right">{row.started ? "Yes" : "No"}</TableCell>
              <TableCell align="right">{row.finished ? "Yes" : "No"}</TableCell>
              <TableCell align="right">{row.percent * 100}%</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function UserInformation({ user, groups }: UserInformationProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isNewUser = user.id === undefined;

  const { isLoading: loadingGroups, data: allGroups } = useOrganizationGroup(0);
  const { isLoading: loadingRoles, data: roles } = useRoles();

  const formik = useFormik<User>({
    initialValues: user,
    validationSchema: isNewUser ? newUserValidationSchema : validationSchema,
    onSubmit: async (values) => {
      try {
        if (user.id) {
          await adminPutOrgUser(values.id!, values);
          enqueueSnackbar(
            `User "${values.first_name} ${values.last_name}" saved successfully.`,
            {
              variant: "success",
              anchorOrigin: {
                horizontal: "right",
                vertical: "bottom",
              },
            }
          );
        } else {
          await adminPostOrgUser(values);
          enqueueSnackbar(
            `User "${values.first_name} ${values.last_name}" created.`,
            {
              variant: "success",
              anchorOrigin: {
                horizontal: "right",
                vertical: "bottom",
              },
            }
          );
          navigate("/admin/users");
        }
      } catch (err) {
        enqueueSnackbar((err as Error).message, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      }
    },
  });

  return (
    <div className="lcol">
      <h2>User Information</h2>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id="email"
              name="email"
              variant="standard"
              label="Email Address"
              fullWidth={true}
              disabled={!isNewUser}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          {isNewUser && (
            <Grid item xs={12}>
              <TextField
                required
                id="password"
                name="password"
                variant="standard"
                label="Password"
                fullWidth={true}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <TextField
              required
              id="first_name"
              name="first_name"
              variant="standard"
              label="First Name"
              fullWidth={true}
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              id="last_name"
              name="last_name"
              variant="standard"
              label="Last Name"
              fullWidth={true}
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
            />
          </Grid>

          <Grid item xs={12}>
            {!loadingRoles && roles && (
              <TextField
                required
                id="role_id"
                name="role_id"
                variant="standard"
                label="Role"
                select
                fullWidth={true}
                value={formik.values.role_id}
                onChange={formik.handleChange}
                error={formik.touched.role_id && Boolean(formik.errors.role_id)}
                helperText={formik.touched.role_id && formik.errors.role_id}
              >
                {roles &&
                  roles.map((r) => {
                    return (
                      <MenuItem key={r.id} value={r.id}>
                        {r.name}
                      </MenuItem>
                    );
                  })}
              </TextField>
            )}
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="approved"
                  id="approved"
                  checked={formik.values.approved}
                  onChange={formik.handleChange}
                />
              }
              label="Approved"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="blocked"
                  id="blocked"
                  checked={formik.values.blocked}
                  onChange={formik.handleChange}
                />
              }
              label="Blocked"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="Notes"
              fullWidth
              label="User Notes"
              multiline
              rows={6}
              variant="standard"
              name="notes"
              onChange={formik.handleChange}
              value={formik.values.notes ?? ""}
              error={formik.touched.notes && Boolean(formik.errors.notes)}
              helperText={formik.touched.notes && formik.errors.notes}
            />
          </Grid>

          <Grid item xs={12}>
            <Autocomplete
              style={{ marginBottom: 20 }}
              multiple
              fullWidth={true}
              loading={loadingGroups}
              options={allGroups ? allGroups.groups : []}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              defaultValue={groups}
              onChange={(event, value) => {
                const groupMap: any = {};
                value.forEach((v) => {
                  groupMap[v.id!] = v.name;
                });
                formik.setFieldValue("groups", groupMap);
              }}
              id="groups"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Assigned Groups"
                  placeholder="Add Group"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loadingGroups ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              disabled={formik.isSubmitting}
            >
              Save User
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
