import { useQuery } from "react-query";
import { Role } from "../lib/definitions";
import { adminGetRoles } from "../lib/http";

export function useRoles() {
  return useQuery<Role[], Error>(
    "admin_roles",
    () => {
      return adminGetRoles();
    },
    { enabled: true }
  );
}
