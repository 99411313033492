import { useQuery } from "react-query";
import { AdminUserResponse } from "../lib/definitions";
import { adminGetOrgUsers } from "../lib/http";

export function useOrganizationUsers(currentPage: number, searchTerm: string) {
  return useQuery<AdminUserResponse, Error>(
    [
      "admin_users",
      {
        page: currentPage,
        term: searchTerm,
      },
    ],
    () => {
      return adminGetOrgUsers(currentPage, searchTerm);
    },
    {
      enabled: true,
    }
  );
}
