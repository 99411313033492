import { useQuery } from "react-query";
import { SingleGroup } from "../lib/definitions";
import { adminGetGroup } from "../lib/http";

export function useSingleGroup(id: string) {
  return useQuery<SingleGroup, Error>(
    ["group", { id }],
    () => {
      return adminGetGroup(id);
    },
    { enabled: true }
  );
}
