import * as yup from "yup";
import { useFormik } from "formik";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useVideoCategories } from "../../hooks/useVideoCategories";
import { adminBulkAssignVideoCategory } from "../../lib/http";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface AssignVideoCategoryDialogProps {
  selection: string[];
  open: boolean;
  onClose: (success: boolean) => void;
}

interface FormValues {
  categoryId: string;
}

const validationSchema = yup.object({
  categoryId: yup.string().required("Category is required."),
});

export function AssignVideoCategoryDialog({
  selection,
  open,
  onClose,
}: AssignVideoCategoryDialogProps) {
  const { isLoading: isCategoriesLoading, data: categories } =
    useVideoCategories();

  const formik = useFormik<FormValues>({
    initialValues: {
      categoryId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await adminBulkAssignVideoCategory(selection, values.categoryId);
        onFormClose(true);
      } catch (err) {
        formik.setFieldError("categoryId", (err as Error).message);
        formik.setSubmitting(false);
      }
    },
  });

  const onFormClose = (success: boolean) => {
    formik.resetForm();
    onClose(success);
  };

  const onFormSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onBackdropClick={() => onClose(false)}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Assign Category to Video</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth={true}
                loading={isCategoriesLoading}
                id="categories"
                options={categories ?? []}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(event, value, reason) => {
                  formik.setFieldValue("categoryId", value ? value.id! : "");
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categories"
                    placeholder="Categories"
                    variant="standard"
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          onClick={() => onFormClose(false)}
        >
          Cancel
        </Button>
        <Button disabled={formik.isSubmitting} onClick={onFormSubmit}>
          Assign {selection.length} videos to Category
        </Button>
      </DialogActions>
    </Dialog>
  );
}
