import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { Card, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DateTimePicker from "@mui/lab/DateTimePicker";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";

import { EntitySelect } from "../components/EntitySelect";
import { VideoPlayer } from "../components/VideoPlayer";

import { useSingleVideo } from "../hooks/useSingleVideo";

import { Entity, Video } from "../lib/definitions";
import { adminPutVideo } from "../lib/http";

import styles from "./AdminVideoEdit.module.css";

const validationSchema = yup.object({
  title: yup.string().required("Video title is required."),
  source: yup.string().required("Video source type is required."),
  url: yup.string().required("Video source url is required."),
});

interface VideoInformationProps {
  video: Video;
  groups: Entity[];
  categories: Entity[];
  files: Entity[];
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 36,
      height: 36,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export function AdminVideoEdit() {
  const params = useParams();
  const navigate = useNavigate();

  const { isLoading, data } = useSingleVideo(params.videoId ?? "");

  const videoGroups: Entity[] = data
    ? Object.keys(data.groups).map((g) => {
        return {
          name: data.groups[g],
          id: g,
          type: "Group",
        };
      })
    : [];

  const videoCategories: Entity[] = data
    ? Object.keys(data.categories).map((c) => {
        return {
          name: data.categories[c],
          id: c,
          type: "Category",
        };
      })
    : [];

  const videoFiles: Entity[] = data
    ? Object.keys(data.files).map((f) => {
        return {
          name: data.files[f],
          id: f,
          type: "File",
        };
      })
    : [];

  const viewedByUsers: Entity[] = data
    ? Object.keys(data.viewed_by).map((v) => {
        return {
          name: data.viewed_by[v],
          id: v,
          type: "User",
        };
      })
    : [];

  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>
            <IconButton size="large" onClick={() => navigate("/admin/videos")}>
              <KeyboardBackspaceIcon fontSize="inherit" />
            </IconButton>
            Video: {isLoading && <Skeleton variant="text" />}{" "}
            {!isLoading && data && <>{data.title}</>}
          </h1>
        </div>
      </header>

      <div className="page_content">
        <div className="inner">
          <div className={styles.videoEditor}>
            {!isLoading && data && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <VideoInformation
                  video={data}
                  groups={videoGroups}
                  categories={videoCategories}
                  files={videoFiles}
                />
              </LocalizationProvider>
            )}
            <div className="rcol">
              <h2>Video Preview</h2>
              {!isLoading && data && (
                <VideoPlayer video={data} withAnalytics={false} />
              )}

              <h2>Viewed By</h2>
              <List>
                {viewedByUsers.map((u) => {
                  return (
                    <ListItem
                      key={u.id}
                      onDoubleClick={() => {
                        navigate(`/admin/users/${u.id}`);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar {...stringAvatar(u.name)} />
                      </ListItemAvatar>
                      <ListItemText primary={u.name} />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

function VideoInformation({
  video,
  groups,
  categories,
  files,
}: VideoInformationProps) {
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik<Video>({
    initialValues: video,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await adminPutVideo(values.id!, values);
        enqueueSnackbar(`Video "${video.title}" saved successfully.`, {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      } catch (err) {
        enqueueSnackbar((err as Error).message, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      }
    },
  });
  return (
    <div className="lcol">
      <h2>Video Information</h2>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id="title"
              name="title"
              variant="standard"
              label="Video Title"
              fullWidth={true}
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              required
              select
              id="source"
              name="source"
              variant="standard"
              label="Source"
              fullWidth={true}
              disabled={true}
              value={formik.values.source}
              onChange={formik.handleChange}
              error={formik.touched.source && Boolean(formik.errors.source)}
              helperText={formik.touched.source && formik.errors.source}
            >
              <MenuItem key="youtube" value="youtube">
                YouTube
              </MenuItem>
              <MenuItem key="vimeo" value="vimeo">
                Vimeo
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <TextField
              required
              id="url"
              name="url"
              variant="standard"
              label="URL"
              disabled={true}
              fullWidth={true}
              value={formik.values.url}
              onChange={formik.handleChange}
              error={formik.touched.url && Boolean(formik.errors.url)}
              helperText="Editing the video url will invalidate recorded analytics. Create a new video instead."
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              select
              id="status"
              name="status"
              variant="standard"
              label="Publish Status"
              fullWidth={true}
              value={formik.values.status}
              onChange={formik.handleChange}
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
            >
              <MenuItem key="unpublished" value="unpublished">
                Unpublished
              </MenuItem>
              <MenuItem key="published" value="published">
                Published
              </MenuItem>
              <MenuItem key="scheduled" value="scheduled">
                Scheduled
              </MenuItem>
            </TextField>
          </Grid>

          {formik.values.status === "scheduled" && (
            <Grid item xs={12}>
              <DateTimePicker
                renderInput={(props) => (
                  <TextField
                    fullWidth={true}
                    name="published_at"
                    id="published_at"
                    variant="standard"
                    {...props}
                  />
                )}
                label="Published At Date"
                value={formik.values.published_at}
                onChange={(date) => {
                  formik.setFieldValue("published_at", date);
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Card variant="outlined" className={styles.entitySelector}>
              <h4>Categories</h4>
              <EntitySelect
                type="Category"
                initialSelection={categories}
                onSelectionChange={(entities) => {
                  const selectedCategories: any = {};
                  entities.forEach((e) => {
                    selectedCategories[e.id] = e.name;
                  });
                  formik.setFieldValue("categories", selectedCategories);
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined" className={styles.entitySelector}>
              <h4>Groups</h4>
              <EntitySelect
                type="Group"
                initialSelection={groups}
                onSelectionChange={(entities) => {
                  const selectedGroups: any = {};
                  entities.forEach((e) => {
                    selectedGroups[e.id] = e.name;
                  });
                  formik.setFieldValue("groups", selectedGroups);
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined" className={styles.entitySelector}>
              <h4>Files</h4>
              <EntitySelect
                type="File"
                initialSelection={files}
                onSelectionChange={(entities) => {
                  const selectedFiles: any = {};
                  entities.forEach((e) => {
                    selectedFiles[e.id] = e.name;
                  });
                  formik.setFieldValue("files", selectedFiles);
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained">
              Save Video
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
