import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { createBrowserHistory } from "history";

import { Dashboard } from "./pages/Dashboard";
import { Login } from "./pages/Login";
import { AdminUsers } from "./pages/AdminUsers";
import { AdminVideos } from "./pages/AdminVideos";
import { AdminFiles } from "./pages/AdminFiles";
import { AdminGroupEdit } from "./pages/AdminGroupEdit";
import { AdminUserEdit } from "./pages/AdminUserEdit";
import { AdminVideoEdit } from "./pages/AdminVideoEdit";
import { UserVideoFiles } from "./pages/UserVideosFiles";
import { UserVideo } from "./pages/UserVideo";
import { AdminFilesEdit } from "./pages/AdminFilesEdit";
import { AdminCategoryEdit } from "./pages/AdminCategoryEdit";
import { AdminMessages } from "./pages/AdminMessages";
import { AdminMessageEdit } from "./pages/AdminMessageEdit";
import { UserMessages } from "./pages/UserMessages";

export const history = createBrowserHistory();

function RequireAuth({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useAuth0();
  if (isAuthenticated) {
    return children;
  }

  const redirectTo = window.location.pathname;
  return <Navigate to={`/login?returnTo=${redirectTo}`} />;
}

function RequireAdmin({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return children;
  }
  return <Navigate to={"/"} />;
}

export function AppRouter() {
  // const {} = useAuth();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route path="" element={<UserVideoFiles />} />
          <Route path="/messages" element={<UserMessages />} />
          <Route path="/video/:videoId" element={<UserVideo />} />
          <Route
            path="admin/users"
            element={
              <RequireAdmin>
                <AdminUsers />
              </RequireAdmin>
            }
          />
          <Route
            path="admin/users/:userId"
            element={
              <RequireAdmin>
                <AdminUserEdit />
              </RequireAdmin>
            }
          />

          <Route
            path="admin/users/group/:groupId"
            element={
              <RequireAdmin>
                <AdminGroupEdit />
              </RequireAdmin>
            }
          />
          <Route
            path="admin/videos"
            element={
              <RequireAdmin>
                <AdminVideos />
              </RequireAdmin>
            }
          />
          <Route
            path="admin/videos/:videoId"
            element={
              <RequireAdmin>
                <AdminVideoEdit />
              </RequireAdmin>
            }
          />
          <Route
            path="admin/videos/category/:videoCategoryId"
            element={
              <RequireAdmin>
                <AdminCategoryEdit />
              </RequireAdmin>
            }
          />
          <Route
            path="admin/files"
            element={
              <RequireAdmin>
                <AdminFiles />
              </RequireAdmin>
            }
          />
          <Route
            path="admin/files/:fileId"
            element={
              <RequireAdmin>
                <AdminFilesEdit />
              </RequireAdmin>
            }
          />
          <Route
            path="admin/messages"
            element={
              <RequireAdmin>
                <AdminMessages />
              </RequireAdmin>
            }
          />
          <Route
            path="admin/messages/:msgId"
            element={
              <RequireAdmin>
                <AdminMessageEdit />
              </RequireAdmin>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
