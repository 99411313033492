import "./App.css";

import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "react-query";

import { AppRouter, history } from "./router";
import HttpContextProvider from "./hooks/useHttp";
import AuthContextProvider from "./hooks/useAuth";

const onRedirectCallback = (appState: AppState) => {
  history.replace((appState && appState.returnTo) || window.location.pathname);
};

const queryClient = new QueryClient();

function App() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope="openid email profile"
      cacheLocation="localstorage"
    >
      <HttpContextProvider>
        <AuthContextProvider>
          <div className="app">
            <QueryClientProvider client={queryClient}>
              <AppRouter />
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
          </div>
        </AuthContextProvider>
      </HttpContextProvider>
    </Auth0Provider>
  );
}

export default App;
