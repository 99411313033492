import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import styles from "./Login.module.css";

import logo from "../assets/logo.svg";
import loginBg from "../assets/login_bg.svg";
import brandLogo from "../assets/brands/century21_northland.png";

export function Login() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const returnTo = urlParams.get("returnTo");

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  const onLoginClick = () => {
    loginWithRedirect({
      appState: {
        returnTo,
      },
    });
  };

  const onSignupClick = () => {
    loginWithRedirect({
      screen_hint: "signup",
      appState: {
        returnTo,
      },
    });
  };

  return (
    <div className="page">
      <div className={styles.loginScreen}>
        <div className={styles.loginPane}>
          <header>
            <img className={styles.logo} src={logo} alt="VideoWatch" />
          </header>
          <div className={styles.form}>
            <h2>Platform Sign-In</h2>
            <button onClick={onLoginClick} className="button">
              Login
            </button>
            <button onClick={onSignupClick} className="button">
              Sign Up
            </button>
          </div>
        </div>
        <div className={styles.brandPane}>
          <div className={styles.brandLogoPane}>
            <img src={brandLogo} alt="Century 21 Northland" />
          </div>
          <img src={loginBg} className={styles.brandOverlayImage} />
        </div>
      </div>
    </div>
  );
}
