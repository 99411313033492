import { useNavigate } from "react-router-dom";
import { Video } from "../lib/definitions";

import styles from "./VideoThumbnail.module.css";

interface VideoThumbnailProps {
  video: Video;
}

export function VideoThumbnail({ video }: VideoThumbnailProps) {
  const navigate = useNavigate();
  return (
    <article
      className={styles.video}
      onClick={() => {
        navigate(`/video/${video.id!}`);
      }}
    >
      <div
        className={styles.thumbnail}
        style={{ backgroundImage: `url(${video.thumbnail_url})` }}
      ></div>
      <h1>{video.title}</h1>
    </article>
  );
}
