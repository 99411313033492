import { useAuth0 } from "@auth0/auth0-react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

import { UserStatus } from "../lib/definitions";
import styles from "./UserMessage.module.css";

interface UserMessageProps {
  status: UserStatus;
  error?: Error;
}

export function UserMessage({ status, error }: UserMessageProps) {
  const { logout } = useAuth0();

  const handleLogoutClick = () => {
    logout({
      returnTo: window.location.origin + "/login",
    });
  };

  return (
    <div className={styles.userMessageScreen}>
      <div className={styles.container}>
        {status === "Error" && (
          <>
            <h1>There was a problem logging in</h1>
            <Alert severity="error">{error?.message}</Alert>
            <p>Please contact your administrator for more information.</p>
            <br />
            <br />
            <Button
              variant="contained"
              color="error"
              onClick={handleLogoutClick}
            >
              Logout
            </Button>
          </>
        )}

        {status === "Blocked" && (
          <>
            <h1>Your account has been blocked</h1>
            <p>
              Please contact your administrator for more information regarding
              account status.
            </p>
            <br />
            <br />
            <Button
              variant="contained"
              color="error"
              onClick={handleLogoutClick}
            >
              Logout
            </Button>
          </>
        )}

        {status === "Approval" && (
          <>
            <h1>Account approval required to continue</h1>
            <p>
              Please contact your administrator for more information regarding
              account status.
            </p>
            <br />
            <br />
            <Button
              variant="contained"
              color="error"
              onClick={handleLogoutClick}
            >
              Logout
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
