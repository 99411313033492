import * as yup from "yup";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Grid, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import { adminPostFile } from "../../lib/http";

interface FileUploadDialogProps {
  open: boolean;
  onClose: () => void;
}

const validationSchema = yup.object({
  file: yup.mixed().required("A file is required to upload"),
});

export function FileUploadDialog({ open, onClose }: FileUploadDialogProps) {
  const formik = useFormik<any>({
    initialValues: {
      file: null,
      file_name: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      try {
        await adminPostFile(values.file, values.file_name);
        onFormClose();
      } catch (err) {
        formik.setFieldError("file_name", (err as Error).message);
        formik.setSubmitting(false);
      }
    },
  });

  const onFormClose = () => {
    formik.resetForm();
    onClose();
  };

  const onFormSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onBackdropClick={onClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Upload New File</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="file_name"
                name="file_name"
                variant="standard"
                label="File Name (optional)"
                fullWidth={true}
                value={formik.values.file_name}
                onChange={formik.handleChange}
                error={
                  formik.touched.file_name && Boolean(formik.errors.file_name)
                }
                helperText={formik.touched.file_name && formik.errors.file_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="file"
                name="file"
                type={"file"}
                variant="outlined"
                fullWidth={true}
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLInputElement>
                ) => {
                  if (e.currentTarget.files) {
                    const file = e.currentTarget.files[0];
                    formik.setFieldValue("file", file);
                  }
                }}
                error={formik.touched.file && Boolean(formik.errors.file)}
                helperText={formik.touched.file && formik.errors.file}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton disabled={formik.isSubmitting} onClick={onFormClose}>
          Cancel
        </LoadingButton>
        <LoadingButton
          loadingIndicator="...Uploading..."
          loading={formik.isSubmitting}
          onClick={onFormSubmit}
        >
          Upload File
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
