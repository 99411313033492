import axios from "axios";
import { parseJSON } from "date-fns";
import {
  User,
  AdminUserResponse,
  AdminVideoResponse,
  AdminGroupResponse,
  VideoCategory,
  Group,
  Invitation,
  SingleGroup,
  Role,
  Video,
  UserVideoResponse,
  SearchResults,
  SingleUserAnalytics,
  AdminFileResponse,
  OrgFile,
  Entity,
  UserSingleVideo,
  AdminMessageResponse,
  Message,
  UserMessageResponse,
} from "./definitions";

export async function currentUser(): Promise<User> {
  try {
    const res = await axios.get<User>("/user");
    return res.data;
  } catch (err: any) {
    console.log("currentUser Error", JSON.stringify(err));
    console.log(err.toJSON());
    throw err;
  }
}

export async function validateUser(): Promise<string> {
  try {
    const res = await axios.get("/user/validate");
    const status = res.data as string;

    return status;
  } catch (err: any) {
    console.log("validateUser Error", JSON.stringify(err));
    console.log(err.toJSON());
    throw err;
  }
}

export async function registerUser(newUser: User): Promise<void> {
  try {
    await axios.post("/user/register", newUser);
  } catch (err: any) {
    console.log("registerUser Error", JSON.stringify(err));
    console.log(err.toJSON());
    throw err;
  }
}

export async function adminGetRoles(): Promise<Role[]> {
  try {
    const res = await axios.get<Role[]>(`/admin/roles`);
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetOrgUsers(
  currentPage: number,
  searchTerm: string
): Promise<AdminUserResponse> {
  try {
    const res = await axios.get<AdminUserResponse>(
      `/admin/users?page=${currentPage}&search=${searchTerm}`
    );
    res.data.users.forEach((user) => {
      if (user.created_at) {
        user.created_at = parseJSON(user.created_at as string);
      }
    });
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetOrgUserAnalytics(
  id: string
): Promise<SingleUserAnalytics> {
  try {
    const res = await axios.get<SingleUserAnalytics>(
      `/admin/users/${id}/analytics`
    );
    res.data.videos.forEach((v) => {
      v.viewed_at = parseJSON(v.viewed_at as string);
    });
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminSetOrgUserApproval(
  id: string,
  approved: boolean
): Promise<void> {
  try {
    await axios.post(`/admin/users/${id}/approval`, {
      approved,
    });
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminSetOrgUserBlocked(
  id: string,
  blocked: boolean
): Promise<void> {
  try {
    await axios.post(`/admin/users/${id}/blocked`, {
      blocked,
    });
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPostOrgUser(user: User): Promise<void> {
  try {
    await axios.post(`/admin/users`, user);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetOrgUser(id: string): Promise<User> {
  try {
    const res = await axios.get<User>(`/admin/users/${id}`);
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPutOrgUser(id: string, user: User): Promise<void> {
  try {
    await axios.put(`/admin/users/${id}`, user);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPostOrgUserGroups(
  id: string,
  groupIDs: string[]
): Promise<void> {
  try {
    await axios.post(`/admin/users/${id}/groups`, groupIDs);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetOrgGroups(
  currentPage: number,
  searchTerm: string
): Promise<AdminGroupResponse> {
  try {
    const res = await axios.get<AdminGroupResponse>(
      `/admin/groups?page=${currentPage}&search=${searchTerm}`
    );
    res.data.groups.forEach((group) => {
      if (group.created_at) {
        group.created_at = parseJSON(group.created_at as string);
      }

      if (group.updated_at) {
        group.updated_at = parseJSON(group.updated_at as string);
      }
    });
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetGroup(id: string): Promise<SingleGroup> {
  try {
    const res = await axios.get<SingleGroup>(`/admin/groups/${id}`);
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPostGroup(newGroup: Group): Promise<void> {
  try {
    await axios.post("/admin/groups", newGroup);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPutGroup(id: string, group: Group): Promise<void> {
  try {
    await axios.put(`/admin/groups/${id}`, group);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminBulkAssignUserGroup(
  userIds: string[],
  groupId: string
): Promise<void> {
  try {
    await axios.post(`/admin/groups/${groupId}/userassign`, userIds);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminBulkAssignVideoGroup(
  videoIds: string[],
  groupId: string
): Promise<void> {
  try {
    await axios.post(`/admin/groups/${groupId}/videoassign`, videoIds);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminBulkAssignVideoCategory(
  videoIds: string[],
  categoryId: string
): Promise<void> {
  try {
    await axios.post(`/admin/video-categories/${categoryId}/assign`, videoIds);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetInvitations(): Promise<Invitation[]> {
  try {
    const res = await axios.get<Invitation[]>(`/admin/invitations`);
    res.data.forEach((invitation) => {
      if (invitation.created_at) {
        invitation.created_at = parseJSON(invitation.created_at as string);
      }
    });
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPostInvitation(email: string): Promise<void> {
  try {
    await axios.post("/admin/invitations", {
      email,
    });
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminDeleteInvitations(ids: string[]): Promise<void> {
  try {
    const idString = ids.join(",");
    await axios.delete(`/admin/invitations?ids=${idString}`);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminDeleteVideos(ids: string[]): Promise<void> {
  try {
    const idString = ids.join(",");
    await axios.delete(`/admin/videos?ids=${idString}`);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetVideo(id: string): Promise<Video> {
  try {
    const res = await axios.get<Video>(`/admin/videos/${id}`);
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetVideos(
  currentPage: number,
  searchTerm: string
): Promise<AdminVideoResponse> {
  try {
    const res = await axios.get<AdminVideoResponse>(
      `/admin/videos?page=${currentPage}&search=${searchTerm}`
    );
    res.data.videos.forEach((video) => {
      if (video.created_at) {
        video.created_at = parseJSON(video.created_at as string);
      }
    });
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetMessages(
  currentPage: number,
  searchTerm: string
): Promise<AdminMessageResponse> {
  try {
    const res = await axios.get<AdminMessageResponse>(
      `/admin/messages?page=${currentPage}&search=${searchTerm}`
    );
    res.data.messages.forEach((message) => {
      if (message.created_at) {
        message.created_at = parseJSON(message.created_at as string);
      }

      if (message.send_at) {
        message.send_at = parseJSON(message.send_at as string);
      }
    });
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetMessage(id: string): Promise<Message> {
  try {
    if (id === "new") {
      return {
        title: "",
        message: "",
        to_groups: {},
        send_at: new Date(),
      };
    }

    const res = await axios.get<Message>(`/admin/messages/${id}`);

    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPostMessage(message: Message): Promise<void> {
  try {
    await axios.post("/admin/messages", message);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPutMessage(
  id: string,
  message: Message
): Promise<void> {
  try {
    await axios.put(`/admin/messages/${id}`, message);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetFile(id: string): Promise<any> {
  try {
    const res = await axios.get<OrgFile>(`/admin/files/${id}`);
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetFiles(
  currentPage: number,
  searchTerm: string
): Promise<AdminFileResponse> {
  try {
    const res = await axios.get<AdminFileResponse>(
      `/admin/files?page=${currentPage}&search=${searchTerm}`
    );
    res.data.files.forEach((file) => {
      if (file.created_at) {
        file.created_at = parseJSON(file.created_at as string);
      }
    });
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPostFile(
  file: File,
  file_name: string
): Promise<any> {
  try {
    const postData = new FormData();
    postData.append("file", file);
    postData.append("file_name", file_name);

    await axios.post(`/admin/files`, postData);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPutFile(id: string, file: OrgFile): Promise<any> {
  try {
    await axios.put(`/admin/files/${id}`, file);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminDeleteFiles(ids: string[]): Promise<void> {
  try {
    const idString = ids.join(",");
    await axios.delete(`/admin/files?ids=${idString}`);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetVideoCategories(): Promise<VideoCategory[]> {
  try {
    const res = await axios.get<VideoCategory[]>(`/admin/video-categories`);
    res.data.forEach((category) => {
      if (category.created_at) {
        category.created_at = parseJSON(category.created_at as string);
      }

      if (category.updated_at) {
        category.updated_at = parseJSON(category.updated_at as string);
      }
    });
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPostVideoCategory(
  newCategory: VideoCategory
): Promise<void> {
  try {
    await axios.post(`/admin/video-categories`, newCategory);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminGetVideoCategory(
  id: string
): Promise<VideoCategory> {
  try {
    const res = await axios.get<VideoCategory>(`/admin/video-categories/${id}`);
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPutVideoCategory(
  id: string,
  category: VideoCategory
): Promise<void> {
  try {
    await axios.put(`/admin/video-categories/${id}`, category);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminDeleteVideoCategories(ids: string[]): Promise<any> {
  try {
    const idString = ids.join(",");
    await axios.delete(`/admin/video-categories?ids=${idString}`);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPostVideo(newVideo: Video): Promise<void> {
  try {
    await axios.post(`/admin/videos`, newVideo);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPutVideo(id: string, video: Video): Promise<void> {
  try {
    await axios.put(`/admin/videos/${id}`, video);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminPostVideoGroups(
  id: string,
  groupIDs: string[]
): Promise<void> {
  try {
    await axios.post(`/admin/videos/${id}/groups`, groupIDs);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminBulkDeleteUsers(ids: string[]): Promise<void> {
  try {
    await axios.post(`/admin/users/delete`, ids);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function adminBulkDeleteGroups(ids: string[]): Promise<void> {
  try {
    await axios.post(`/admin/groups/delete`, ids);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function getUserVideos(
  currentPage: number,
  sortBy: string,
  category: string
): Promise<UserVideoResponse> {
  try {
    if (category === "favorites") {
      const res = await axios.get(
        `/videos/?page=${currentPage}&sortBy=${sortBy}&cat=`
      );

      const data = res.data as UserVideoResponse;
      data.videos = data.favorites;
      data.total = data.favorites.length;
      return data;
    } else {
      const res = await axios.get(
        `/videos/?page=${currentPage}&sortBy=${sortBy}&cat=${category}`
      );
      return res.data;
    }
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function getUserVideo(id: string): Promise<UserSingleVideo> {
  try {
    const res = await axios.get<UserSingleVideo>(`/videos/${id}`);
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function getUserMessages(): Promise<UserMessageResponse> {
  try {
    const res = await axios.get<UserMessageResponse>("/messages/");

    let unread = res.data.messages.length;

    res.data.messages.forEach((message) => {
      if (message.created_at) {
        message.created_at = parseJSON(message.created_at as string);
      }

      if (message.send_at) {
        message.send_at = parseJSON(message.send_at as string);
      }

      if (res.data.read.includes(message.id!)) {
        unread--;
      }
    });

    return { ...res.data, unreadCount: unread };
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function markMessageRead(id: string): Promise<void> {
  try {
    await axios.post(`/messages/${id}`);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function markFavoriteVideo(id: string): Promise<void> {
  try {
    await axios.post(`/videos/${id}/favorite`);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function removeFavoriteVideo(id: string): Promise<void> {
  try {
    await axios.delete(`/videos/${id}/favorite`);
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function getSearchResults(term: string): Promise<Entity[]> {
  try {
    if (term.length < 3) {
      return [];
    }
    const res = await axios.get<SearchResults>(`/search/?term=${term}`);

    const results: Entity[] = [];
    if (res.data.videos) {
      res.data.videos.forEach((v) => {
        results.push({
          id: v.id!,
          name: v.title,
          thumbnail: v.thumbnail_url,
          type: "Video",
        });
      });
    }

    if (res.data.groups) {
      res.data.groups.forEach((g) => {
        results.push({
          id: g.id!,
          name: g.name,
          type: "Group",
        });
      });
    }

    if (res.data.files) {
      res.data.files.forEach((f) => {
        results.push({
          id: f.id!,
          name: f.file_name,
          type: "File",
        });
      });
    }

    if (res.data.categories) {
      res.data.categories.forEach((c) => {
        results.push({
          id: c.id!,
          name: c.name,
          type: "Category",
        });
      });
    }

    return results;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function getUserFiles(): Promise<OrgFile[]> {
  try {
    const res = await axios.get<OrgFile[]>(`/files/`);
    res.data.forEach((f) => {
      if (f.created_at) {
        f.created_at = parseJSON(f.created_at as string);
      }
    });
    return res.data;
  } catch (err) {
    throw new Error((err as Error).message);
  }
}

export async function downloadFile(id: string): Promise<void> {
  try {
    const res = await axios.get<string>(`/files/${id}/download`);
    const downloadURL = res.data;

    window.open(downloadURL, "_self");
  } catch (err) {
    throw new Error((err as Error).message);
  }
}
