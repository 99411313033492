import { useQuery } from "react-query";
import { VideoCategory } from "../lib/definitions";
import { adminGetVideoCategory } from "../lib/http";

export function useSingleCategory(id: string) {
  return useQuery<VideoCategory, Error>(
    ["video_category", { id }],
    () => {
      return adminGetVideoCategory(id);
    },
    { enabled: true }
  );
}
