import { useState } from "react";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import filesize from "filesize";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { AdminFileResponse, OrgFile } from "../lib/definitions";
import { adminDeleteFiles, adminGetFiles } from "../lib/http";
import { Fab, Grid, Popover, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { FileUploadDialog } from "../components/dialogs/FileUpload";
import { useDebouncedEffect } from "../hooks/useDebouncedEffect";

const columns: GridColDef[] = [
  { field: "file_name", headerName: "Name", width: 350, sortable: false },
  { field: "file_type", headerName: "Type", width: 90, sortable: false },
  {
    field: "size",
    headerName: "Size",
    width: 130,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${filesize(params.row.size)}
`,
  },
  {
    field: "groups",
    headerName: "Groups",
    width: 100,
    sortable: false,
    renderCell: (params: GridRenderCellParams<OrgFile>) => {
      return (
        <AssociationCountPopover
          title="In Groups: "
          groups={params.row.groups}
        />
      );
    },
  },
  {
    field: "videos",
    headerName: "Videos",
    width: 100,
    sortable: false,
    renderCell: (params: GridRenderCellParams<OrgFile>) => {
      return (
        <AssociationCountPopover
          title="In Videos: "
          groups={params.row.videos}
        />
      );
    },
  },
  {
    field: "created_at",
    headerName: "Added At",
    sortable: false,
    width: 300,
    valueGetter: (params: GridValueGetterParams) =>
      `${format(params.value as Date, "MM/dd/yyyy - hh:mmaa")}
  `,
  },
];

function useFiles(currentPage: number, searchTerm: string) {
  return useQuery<AdminFileResponse, Error>(
    [
      "admin_files",
      {
        page: currentPage,
        term: searchTerm,
      },
    ],
    () => {
      return adminGetFiles(currentPage, searchTerm);
    },
    {
      enabled: true,
    }
  );
}

interface SearchFieldProps {
  label: string;
  onSearch: (term: string) => void;
}

export function AdminFiles() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentSelection, setCurrentSelection] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [showFileUploadDialog, setShowFileUploadDialog] =
    useState<boolean>(false);

  const { isLoading, data, refetch } = useFiles(currentPage, searchTerm);

  const onFileUploadClick = () => {
    setShowFileUploadDialog(true);
  };

  const onDeleteClick = async () => {
    const videoConfirmation = window.confirm(
      `Are you sure you want to delete these ${currentSelection.length} files?`
    );
    if (videoConfirmation) {
      await adminDeleteFiles(currentSelection);
      setCurrentSelection([]);
      refetch();
    }
  };

  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>File Management</h1>
        </div>
      </header>

      <div className="page_content">
        <div className="inner">
          <Grid container spacing={2} style={{ marginBottom: 30 }}>
            <Grid item xs={8}>
              <Search
                label="Search for files"
                onSearch={(term) => {
                  setCurrentSelection([]);
                  setCurrentPage(0);
                  setSearchTerm(term);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <div className="toolbarContainer">
                <Fab
                  color="primary"
                  size="medium"
                  variant="extended"
                  onClick={onFileUploadClick}
                >
                  <AddIcon /> Upload File
                </Fab>
                <Fab
                  color="default"
                  size="small"
                  disabled={currentSelection.length === 0}
                  onClick={onDeleteClick}
                >
                  <DeleteIcon />
                </Fab>
              </div>
            </Grid>
          </Grid>

          <div className="dataTableContainer">
            <DataGrid
              loading={isLoading}
              rows={data ? data.files : []}
              columns={columns}
              pageSize={25}
              autoHeight={true}
              paginationMode={"server"}
              rowsPerPageOptions={[25]}
              checkboxSelection
              disableSelectionOnClick
              rowCount={data ? data.total : 0}
              selectionModel={currentSelection}
              onRowDoubleClick={(row) => {
                navigate(`/admin/files/${row.id}`);
              }}
              onPageChange={(page) => {
                setCurrentPage(page);
                refetch();
              }}
              onSelectionModelChange={(model) => {
                setCurrentSelection(model as string[]);
              }}
            />
          </div>
        </div>
      </div>
      <FileUploadDialog
        open={showFileUploadDialog}
        onClose={() => {
          setShowFileUploadDialog(false);
          refetch();
        }}
      />
    </main>
  );
}

function AssociationCountPopover({
  groups,
  title,
}: {
  groups: any;
  title: string;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="userGroupPopover">
      <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {Object.keys(groups).length}
      </div>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div style={{ padding: 15 }}>
          <strong>{title}</strong>
          {Object.keys(groups).map((g) => {
            return <p>{groups[g]}</p>;
          })}
          {Object.keys(groups).length === 0 && <p>None</p>}
        </div>
      </Popover>
    </div>
  );
}

function Search({ label, onSearch }: SearchFieldProps) {
  const [value, setValue] = useState<string>("");

  useDebouncedEffect(() => onSearch(value), [value], 600);

  return (
    <TextField
      fullWidth
      label={label}
      variant="standard"
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
      }}
    />
  );
}
