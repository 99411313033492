import { useNavigate, useParams } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Skeleton from "@mui/material/Skeleton";

import { VideoPlayer } from "../components/VideoPlayer";
import { useVideo } from "../hooks/useVideo";
import { FileListing } from "../components/FileListing";

import styles from "./UserVideo.module.css";
import { markFavoriteVideo, removeFavoriteVideo } from "../lib/http";

export function UserVideo() {
  const params = useParams();
  const navigate = useNavigate();

  const { isLoading, data, error, refetch } = useVideo(params.videoId!);

  const onFavoriteClick = async () => {
    if (data && data.video) {
      try {
        if (data.favorite) {
          await removeFavoriteVideo(data.video.id!);
        } else {
          await markFavoriteVideo(data.video.id!);
        }

        await refetch();
      } catch (err: any) {}
    }
  };

  console.log(error?.message);
  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>
            <IconButton size="large" onClick={() => navigate("/")}>
              <KeyboardBackspaceIcon fontSize="inherit" />
            </IconButton>
            {isLoading && (
              <Skeleton variant="text" animation="wave" width={300} />
            )}
            {!isLoading && data && "Video: " + data.video.title}
            {!isLoading && error && "Go back"}
          </h1>
        </div>
      </header>

      <div className="page_content">
        <div className="inner">
          {!isLoading && data && (
            <>
              <VideoPlayer video={data.video} withAnalytics={true} />
              <div className={styles.buttons}>
                <Button
                  color="error"
                  onClick={onFavoriteClick}
                  startIcon={
                    data.favorite ? <FavoriteIcon /> : <FavoriteBorderIcon />
                  }
                >
                  Favorite
                </Button>
              </div>
            </>
          )}
          {isLoading && (
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={576}
            />
          )}

          {!isLoading && error && (
            <div className={styles.empty}>
              {error.message.includes("404") && (
                <>
                  <h1>404</h1>
                  <h2>Video not found</h2>
                </>
              )}
              {error.message.includes("500") && (
                <>
                  <h1>500</h1>
                  <h2>There was a problem loading this video</h2>
                </>
              )}
              {error.message.includes("401") && (
                <>
                  <h1>401</h1>
                  <h2>You are not authorized to view this video</h2>
                </>
              )}
            </div>
          )}
          {!isLoading && data && <FileListing files={data.files} />}
        </div>
      </div>
    </main>
  );
}
