import { useState } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useDebouncedEffect } from "../hooks/useDebouncedEffect";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import { Checkbox } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupsIcon from "@mui/icons-material/Groups";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";

import { NewGroupDialog } from "../components/dialogs/NewGroup";
import { NewInvitationDialog } from "../components/dialogs/NewInvitation";
import { AssignGroupDialog } from "../components/dialogs/AssignGroup";

import { useOrganizationUsers } from "../hooks/useOrganizationUsers";
import { useOrganizationGroup } from "../hooks/useOrganizationGroup";
import { useInvitations } from "../hooks/useInvitations";
import { useURLQuery } from "../hooks/useQuery";

import {
  adminBulkDeleteGroups,
  adminBulkDeleteUsers,
  adminDeleteInvitations,
  adminSetOrgUserApproval,
  adminSetOrgUserBlocked,
} from "../lib/http";

type UserTabSelection = "invitations" | "users" | "groups";

interface SearchFieldProps {
  label: string;
  onSearch: (term: string) => void;
}

export function AdminUsers() {
  const query = useURLQuery();

  const tab = query.get("tab");
  const [currentTab, setCurrentTab] = useState<UserTabSelection>(
    (tab as UserTabSelection) || "users"
  );

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: UserTabSelection
  ) => {
    setCurrentTab(newValue);
  };

  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>User Administration</h1>
        </div>
      </header>

      <div className="page_content">
        <div className="inner">
          <Box
            sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 5 }}
          >
            <Tabs value={currentTab} onChange={handleTabChange}>
              <Tab value="users" label="Users" />
              <Tab value="groups" label="Groups" />
              <Tab value="invitations" label="Invitations" />
            </Tabs>
          </Box>

          {currentTab === "users" && <UserTab />}

          {currentTab === "groups" && <GroupTab />}

          {currentTab === "invitations" && <InvitationTab />}
        </div>
      </div>
    </main>
  );
}

function GroupTab() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentSelection, setCurrentSelection] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [showNewGroupDialog, setShowNewGroupDialog] = useState<boolean>(false);

  const { isLoading, data, refetch } = useOrganizationGroup(
    currentPage,
    searchTerm
  );

  const onNewGroupClick = () => {
    setShowNewGroupDialog(true);
  };

  const onDeleteClick = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete these ${currentSelection.length} groups?`
      )
    ) {
      try {
        await adminBulkDeleteGroups(currentSelection);
        setCurrentSelection([]);
        refetch();
      } catch (err) {}
    }
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Group", width: 350, sortable: false },
    { field: "users", headerName: "Users", width: 130, sortable: false },
    { field: "videos", headerName: "Videos", width: 130, sortable: false },
    {
      field: "created_at",
      headerName: "Added At",
      width: 250,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${format(params.value as Date, "MM/dd/yyyy - hh:mmaa")}
    `,
    },
  ];

  return (
    <div className="groupTab">
      <Grid container spacing={2} style={{ marginBottom: 30 }}>
        <Grid item xs={6}>
          <Search
            label="Search for groups"
            onSearch={(term) => {
              setCurrentSelection([]);
              setCurrentPage(0);
              setSearchTerm(term);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <div className="toolbarContainer">
            <Fab
              color="primary"
              size="medium"
              variant="extended"
              onClick={onNewGroupClick}
            >
              <AddIcon sx={{ mr: 1 }} /> Add Group
            </Fab>
            <Fab
              color="default"
              size="small"
              disabled={currentSelection.length === 0}
              onClick={onDeleteClick}
            >
              <DeleteIcon />
            </Fab>
          </div>
        </Grid>
      </Grid>
      <div className="dataTableContainer">
        <DataGrid
          loading={isLoading}
          rows={data ? data.groups : []}
          columns={columns}
          pageSize={25}
          autoHeight={true}
          paginationMode={"server"}
          rowsPerPageOptions={[25]}
          checkboxSelection
          disableColumnMenu={true}
          disableSelectionOnClick
          rowCount={data ? data.total : 0}
          selectionModel={currentSelection}
          onRowDoubleClick={(row) => {
            navigate(`/admin/users/group/${row.id}`);
          }}
          onPageChange={(page) => {
            setCurrentPage(page);
            refetch();
          }}
          onSelectionModelChange={(model, details) => {
            setCurrentSelection(model as string[]);
          }}
        />
      </div>

      <NewGroupDialog
        open={showNewGroupDialog}
        onClose={() => {
          refetch();
          setShowNewGroupDialog(false);
        }}
      />
    </div>
  );
}

function InvitationTab() {
  const [currentSelection, setCurrentSelection] = useState<string[]>([]);
  const [showNewInvitationDialog, setShowNewInvitationDialog] =
    useState<boolean>(false);

  const { isLoading, data, refetch } = useInvitations();

  const onNewInvitationClick = () => {
    setShowNewInvitationDialog(true);
  };

  const onDeleteClick = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete these ${currentSelection.length} invitations?`
      )
    ) {
      try {
        await adminDeleteInvitations(currentSelection);
        setCurrentSelection([]);
        refetch();
      } catch (err) {}
    }
  };

  const columns = [
    { field: "email", headerName: "Invitation Email", width: 350 },
    {
      field: "created_at",
      headerName: "Sent At",
      width: 250,
      valueGetter: (params: GridValueGetterParams) =>
        `${format(params.value as Date, "MM/dd/yyyy - hh:mmaa")}
    `,
    },
  ];

  return (
    <div className="userTab">
      <Grid container spacing={2} style={{ marginBottom: 30 }}>
        <Grid item xs={12}>
          <div className="toolbarContainer">
            <Fab
              color="primary"
              size="medium"
              variant="extended"
              onClick={onNewInvitationClick}
            >
              <AddIcon sx={{ mr: 1 }} /> Invite User
            </Fab>

            <Fab
              color="default"
              size="small"
              disabled={currentSelection.length === 0}
              onClick={onDeleteClick}
            >
              <DeleteIcon />
            </Fab>
          </div>
        </Grid>
      </Grid>
      <div className="dataTableContainer">
        <DataGrid
          loading={isLoading}
          rows={data ? data : []}
          columns={columns}
          pageSize={25}
          autoHeight={true}
          paginationMode={"client"}
          rowsPerPageOptions={[25]}
          disableColumnMenu={true}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={currentSelection}
          onSelectionModelChange={(model, details) => {
            setCurrentSelection(model as string[]);
          }}
        />
      </div>
      <NewInvitationDialog
        open={showNewInvitationDialog}
        onClose={() => {
          setShowNewInvitationDialog(false);
        }}
      />
    </div>
  );
}

function UserTab() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [currentSelection, setCurrentSelection] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [showAssignGroupDialog, setShowAssignGroupDialog] =
    useState<boolean>(false);
  const [showNewInvitationDialog, setShowNewInvitationDialog] =
    useState<boolean>(false);

  const { isLoading, data, refetch } = useOrganizationUsers(
    currentPage,
    searchTerm
  );

  const onAssignGroupClick = async () => {
    setShowAssignGroupDialog(true);
  };

  const onDeleteClick = async () => {
    if (
      window.confirm(
        `Are you sure you want to delete these ${currentSelection.length} users?`
      )
    ) {
      try {
        await adminBulkDeleteUsers(currentSelection);
        setCurrentSelection([]);
        refetch();
      } catch (err) {}
    }
  };

  // const onNewInvitationClick = () => {
  //   setShowNewInvitationDialog(true);
  // };

  const onNewUserClick = () => {
    navigate(`/admin/users/new`);
  };

  const onRowApprovalChanged = async (userId: string, approved: boolean) => {
    try {
      await adminSetOrgUserApproval(userId, approved);
      refetch();
    } catch (err) {}
  };

  const onRowBlockedChanged = async (userId: string, blocked: boolean) => {
    try {
      await adminSetOrgUserBlocked(userId, blocked);
      refetch();
    } catch (err) {}
  };

  const columns: GridColDef[] = [
    {
      field: "first_name",
      headerName: "First name",
      width: 130,
      sortable: false,
    },
    {
      field: "last_name",
      headerName: "Last name",
      width: 130,
      sortable: false,
    },
    { field: "email", headerName: "Email", width: 300, sortable: false },
    {
      field: "groups",
      headerName: "Groups",
      width: 100,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <UserGroupPopover groups={params.row.groups} />;
      },
    },
    {
      field: "approved",
      headerName: "Approved",
      width: 100,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<Date>) => {
        return (
          <Checkbox
            checked={params.row.approved}
            onChange={(event, checked) => {
              onRowApprovalChanged(params.row.id, checked);
            }}
          />
        );
      },
    },
    {
      field: "blocked",
      headerName: "Blocked",
      width: 100,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams<Date>) => {
        return (
          <Checkbox
            checked={params.row.blocked}
            onChange={(event, checked) => {
              onRowBlockedChanged(params.row.id, checked);
            }}
          />
        );
      },
    },
    {
      field: "created_at",
      headerName: "Added At",
      width: 250,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${format(params.value as Date, "MM/dd/yyyy - hh:mmaa")}
    `,
    },
  ];

  return (
    <div className="userTab">
      <Grid container spacing={2} style={{ marginBottom: 30 }}>
        <Grid item xs={6}>
          <Search
            label="Search for users"
            onSearch={(term) => {
              setCurrentSelection([]);
              setCurrentPage(0);
              setSearchTerm(term);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <div className="toolbarContainer">
            <Fab
              color="primary"
              size="medium"
              variant="extended"
              onClick={onNewUserClick}
            >
              <AddIcon sx={{ mr: 1 }} /> Create New User
            </Fab>
            <Fab
              color="default"
              size="medium"
              variant="extended"
              disabled={currentSelection.length === 0}
              onClick={onAssignGroupClick}
            >
              <GroupsIcon sx={{ mr: 1 }} /> Assign to Group
            </Fab>
            <Fab
              color="default"
              size="small"
              disabled={currentSelection.length === 0}
              onClick={onDeleteClick}
            >
              <DeleteIcon />
            </Fab>
          </div>
        </Grid>
      </Grid>

      <div className="dataTableContainer">
        <DataGrid
          loading={isLoading}
          rows={data ? data.users : []}
          columns={columns}
          pageSize={25}
          disableColumnMenu={true}
          autoHeight={true}
          paginationMode={"server"}
          rowsPerPageOptions={[25]}
          checkboxSelection
          disableSelectionOnClick
          rowCount={data ? data.total : 0}
          selectionModel={currentSelection}
          onRowDoubleClick={(row) => {
            navigate(`/admin/users/${row.id}`);
          }}
          onPageChange={(page) => {
            setCurrentPage(page);
            refetch();
          }}
          onSelectionModelChange={(model, details) => {
            setCurrentSelection(model as string[]);
          }}
        />
      </div>

      <NewInvitationDialog
        open={showNewInvitationDialog}
        onClose={() => {
          setShowNewInvitationDialog(false);
        }}
      />
      <AssignGroupDialog
        target="users"
        selection={currentSelection}
        open={showAssignGroupDialog}
        onClose={(success) => {
          if (success) {
            setCurrentSelection([]);
            refetch();
          }
          setShowAssignGroupDialog(false);
        }}
      />
    </div>
  );
}

function Search({ label, onSearch }: SearchFieldProps) {
  const [value, setValue] = useState<string>("");

  useDebouncedEffect(() => onSearch(value), [value], 600);

  return (
    <TextField
      fullWidth
      label={label}
      variant="standard"
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
      }}
    />
  );
}

function UserGroupPopover({ groups }: { groups: any }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="userGroupPopover">
      <div onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
        {Object.keys(groups).length}
      </div>
      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div style={{ padding: 15 }}>
          <strong>In Groups: </strong>
          {Object.keys(groups).map((g, i) => {
            return <p key={i}>{groups[g]}</p>;
          })}
        </div>
      </Popover>
    </div>
  );
}
