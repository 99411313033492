import { useQuery } from "react-query";
import { AdminGroupResponse } from "../lib/definitions";
import { adminGetOrgGroups } from "../lib/http";

export function useOrganizationGroup(currentPage: number, searchTerm?: string) {
  return useQuery<AdminGroupResponse, Error>(
    ["admin_groups", { page: currentPage, term: searchTerm }],
    () => {
      return adminGetOrgGroups(currentPage, searchTerm ?? "");
    },
    {
      enabled: true,
    }
  );
}
