import { useQuery } from "react-query";
import { Invitation } from "../lib/definitions";
import { adminGetInvitations } from "../lib/http";

export function useInvitations() {
  return useQuery<Invitation[], Error>(
    "admin_invitations",
    () => {
      return adminGetInvitations();
    },
    { enabled: true }
  );
}
