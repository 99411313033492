import * as yup from "yup";

import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useNavigate, useParams } from "react-router-dom";
import { Entity, Message } from "../lib/definitions";
import { Button, Card, Grid, TextField } from "@mui/material";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { useQuery } from "react-query";
import {
  adminGetMessage,
  adminPostMessage,
  adminPutMessage,
} from "../lib/http";
import { EntitySelect } from "../components/EntitySelect";

import styles from "./AdminMessageEdit.module.css";

const validationSchema = yup.object({
  title: yup.string().required("Message title is required."),
  message: yup.string().required("Message content is required."),
});

function useMessage(id: string) {
  return useQuery<Message, Error>(
    ["admin_message", { id }],
    () => {
      return adminGetMessage(id);
    },
    {
      enabled: true,
    }
  );
}

interface MessageInformationProps {
  message: Message;
  groups: Entity[];
}

export function AdminMessageEdit() {
  const params = useParams();
  const navigate = useNavigate();

  const { isLoading, data } = useMessage(params.msgId ?? "");

  const toGroups: Entity[] = data
    ? Object.keys(data.to_groups).map((g) => {
        return {
          name: data.to_groups[g],
          id: g,
          type: "Group",
        };
      })
    : [];

  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>
            <IconButton
              size="large"
              onClick={() => navigate("/admin/messages")}
            >
              <KeyboardBackspaceIcon fontSize="inherit" />
            </IconButton>
            New Message
          </h1>
        </div>
      </header>
      <div className="page_content">
        <div className="inner">
          <div className="messageEditor">
            {!isLoading && data && (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MessageInformation groups={toGroups} message={data} />
              </LocalizationProvider>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

function MessageInformation({ message, groups }: MessageInformationProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik<Message>({
    initialValues: message,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (message.id) {
          await adminPutMessage(message.id, values);
          enqueueSnackbar(`Message "${values.title}" updated successfully.`, {
            variant: "success",
            anchorOrigin: {
              horizontal: "right",
              vertical: "bottom",
            },
          });
        } else {
          await adminPostMessage(values);
          enqueueSnackbar(`Message "${values.title}" sent successfully.`, {
            variant: "success",
            anchorOrigin: {
              horizontal: "right",
              vertical: "bottom",
            },
          });
        }

        navigate("/admin/messages");
      } catch (err) {
        enqueueSnackbar((err as Error).message, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      }
    },
  });

  return (
    <div className="lcol">
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id="title"
              name="title"
              variant="standard"
              label="Title"
              fullWidth={true}
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="message"
              fullWidth
              label="Message"
              multiline
              rows={10}
              variant="standard"
              name="message"
              onChange={formik.handleChange}
              value={formik.values.message ?? ""}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined" className={styles.entitySelector}>
              <h4>Send To Groups</h4>
              <EntitySelect
                type="Group"
                initialSelection={groups}
                onSelectionChange={(entities) => {
                  const selectedGroups: any = {};
                  entities.forEach((e) => {
                    selectedGroups[e.id] = e.name;
                  });
                  formik.setFieldValue("to_groups", selectedGroups);
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <DateTimePicker
              renderInput={(props) => (
                <TextField
                  fullWidth={true}
                  name="send_at"
                  id="send_at"
                  variant="standard"
                  {...props}
                />
              )}
              label="Send At"
              value={formik.values.send_at}
              onChange={(date) => {
                formik.setFieldValue("send_at", date);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained">
              Send Message
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
