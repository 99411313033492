import * as yup from "yup";
import { useFormik } from "formik";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { adminPostInvitation } from "../../lib/http";

interface NewInvitationDialogProps {
  open: boolean;
  onClose: () => void;
}

interface FormValues {
  email: string;
}

const validationSchema = yup.object({
  email: yup.string().required("User email address is required."),
});

export function NewInvitationDialog({
  open,
  onClose,
}: NewInvitationDialogProps) {
  const formik = useFormik<FormValues>({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await adminPostInvitation(values.email);
        onFormClose();
      } catch (err) {
        formik.setFieldError("email", (err as Error).message);
        formik.setSubmitting(false);
      }
    },
  });

  const onFormClose = () => {
    formik.resetForm();
    onClose();
  };

  const onFormSubmit = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onBackdropClick={onClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Invite User to Organization</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                id="email"
                name="email"
                variant="standard"
                label="Email Address"
                fullWidth={true}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={onFormClose}>
          Cancel
        </Button>
        <Button disabled={formik.isSubmitting} onClick={onFormSubmit}>
          Send Invitation
        </Button>
      </DialogActions>
    </Dialog>
  );
}
