import { useQuery } from "react-query";
import { Message, UserMessageResponse } from "../lib/definitions";
import { getUserMessages } from "../lib/http";

export function useUserMessages() {
  return useQuery<UserMessageResponse, Error>(
    ["messages"],
    () => {
      return getUserMessages();
    },
    {
      enabled: true,
    }
  );
}
