import { useQuery } from "react-query";
import { UserSingleVideo } from "../lib/definitions";
import { getUserVideo } from "../lib/http";

export function useVideo(id: string) {
  return useQuery<UserSingleVideo, Error>(
    ["video", { id }],
    () => {
      return getUserVideo(id);
    },
    {
      enabled: true,
    }
  );
}
