import { useQuery } from "react-query";
import { User } from "../lib/definitions";
import { adminGetOrgUser } from "../lib/http";

export function useSingleUser(id: string) {
  return useQuery<User, Error>(
    [
      "user",
      {
        id,
      },
    ],
    () => {
      if (id === "new") {
        return {
          first_name: "",
          last_name: "",
          password: "",
          confirmPassword: "",
          email: "",
          approved: false,
          blocked: false,
          groups: [],
          role_id: "fbf9e1a5-839a-4249-b311-242f009790a1",
        };
      }
      return adminGetOrgUser(id);
    },
    {
      enabled: true,
    }
  );
}
