import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { useSingleGroup } from "../hooks/useSingleGroup";
import { Group } from "../lib/definitions";

import styles from "./AdminGroupEdit.module.css";
import { adminPutGroup } from "../lib/http";
import { ListItemText } from "@mui/material";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 36,
      height: 36,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

interface GroupInformationProps {
  group: Group;
}

interface UserRosterProps {
  users: any;
}

interface VideoRosterProps {
  videos: any;
}

const validationSchema = yup.object({
  name: yup.string().required("Group name is required."),
});

export function AdminGroupEdit() {
  const params = useParams();
  const navigate = useNavigate();

  const { isLoading, data } = useSingleGroup(params.groupId ?? "");

  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>
            <IconButton
              size="large"
              onClick={() => navigate("/admin/users?tab=groups")}
            >
              <KeyboardBackspaceIcon fontSize="inherit" />
            </IconButton>
            Group: {data ? data.group.name : "...loading..."}
          </h1>
        </div>
      </header>

      <div className="page_content">
        <div className="inner">
          <div className={styles.groupEditor}>
            <div className={styles.fullcol}>
              {!isLoading && data && <GroupInformation group={data.group} />}
            </div>
            <div className={styles.lcol}>
              {!isLoading && data && <UserRoster users={data.users} />}
            </div>
            <div className={styles.rcol}>
              {!isLoading && data && <VideoRoster videos={data.videos} />}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

function GroupInformation({ group }: GroupInformationProps) {
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik<Group>({
    initialValues: group,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await adminPutGroup(group.id!, values);
        enqueueSnackbar(`Group "${group.name}" saved successfully.`, {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      } catch (err) {
        enqueueSnackbar((err as Error).message, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      }
    },
  });

  return (
    <section className="section">
      <h2>Group Information</h2>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id="name"
              name="name"
              variant="standard"
              label="Group Name"
              fullWidth={true}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained">
              Save Group
            </Button>
          </Grid>
        </Grid>
      </form>
    </section>
  );
}

function VideoRoster({ videos }: VideoRosterProps) {
  return (
    <section className="section">
      <h2>Video Roster ({Object.keys(videos).length} videos)</h2>
      <List>
        {Object.keys(videos).map((u) => {
          return (
            <ListItem key={u}>
              <ListItemAvatar>
                <Avatar>
                  <OndemandVideoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={videos[u]} />
            </ListItem>
          );
        })}
        {Object.keys(videos).length === 0 && (
          <ListItem disablePadding>
            <ListItemText primary="No videos in group" />
          </ListItem>
        )}
      </List>
    </section>
  );
}

function UserRoster({ users }: UserRosterProps) {
  return (
    <section className="rcol">
      <h2>User Roster ({Object.keys(users).length} users)</h2>
      <List>
        {Object.keys(users).map((u) => {
          return (
            <ListItem key={u}>
              <ListItemAvatar>
                <Avatar {...stringAvatar(users[u])} />
              </ListItemAvatar>
              <ListItemText primary={users[u]} />
            </ListItem>
          );
        })}
        {Object.keys(users).length === 0 && (
          <ListItem disablePadding>
            <ListItemText primary="No users in group" />
          </ListItem>
        )}
      </List>
    </section>
  );
}
