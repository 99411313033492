import * as yup from "yup";
import { useFormik } from "formik";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";

import { useOrganizationGroup } from "../../hooks/useOrganizationGroup";
import {
  adminBulkAssignUserGroup,
  adminBulkAssignVideoGroup,
} from "../../lib/http";

interface AssignGroupDialogProps {
  target: "videos" | "users";
  selection: string[];
  open: boolean;
  onClose: (success: boolean) => void;
}

interface FormValues {
  groupId: string;
}

const validationSchema = yup.object({
  groupId: yup.string().required("Group is required."),
});

export function AssignGroupDialog({
  target,
  open,
  onClose,
  selection,
}: AssignGroupDialogProps) {
  const { isLoading, data } = useOrganizationGroup(0);
  const formik = useFormik<FormValues>({
    initialValues: {
      groupId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (target === "users") {
          await adminBulkAssignUserGroup(selection, values.groupId);
        }

        if (target === "videos") {
          await adminBulkAssignVideoGroup(selection, values.groupId);
        }

        onFormClose(true);
      } catch (err) {
        formik.setFieldError("groupId", (err as Error).message);
        formik.setSubmitting(false);
      }
    },
  });

  const onFormClose = (success: boolean) => {
    formik.resetForm();
    onClose(success);
  };

  const onFormSubmit = () => {
    formik.handleSubmit();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      onBackdropClick={() => onClose(false)}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>Assign {target} to Group</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                style={{ marginBottom: 20 }}
                fullWidth={true}
                loading={isLoading}
                options={data ? data.groups : []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  formik.setFieldValue("groupId", value ? value.id! : "");
                }}
                id="groups"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Available Groups"
                    error={
                      formik.touched.groupId && Boolean(formik.errors.groupId)
                    }
                    helperText={formik.touched.groupId && formik.errors.groupId}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formik.isSubmitting}
          onClick={() => onFormClose(false)}
        >
          Cancel
        </Button>
        <Button disabled={formik.isSubmitting} onClick={onFormSubmit}>
          Assign {selection.length} {target} to Group
        </Button>
      </DialogActions>
    </Dialog>
  );
}
