import { useAuth0 } from "@auth0/auth0-react";
import {
  Form,
  Formik,
  Field,
  ErrorMessage,
  FormikHelpers,
  FormikProps,
} from "formik";

import * as Yup from "yup";
import { User } from "../lib/definitions";
import { registerUser } from "../lib/http";

import styles from "./RegistrationForm.module.css";

interface RegistrationFormValues {
  firstName: string;
  lastName: string;
  email: string;
  [key: string]: any;
}

const RegistrationFormSchema = Yup.object<RegistrationFormValues>().shape({
  email: Yup.string().email().required("Email address required"),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
});

export function RegistrationForm() {
  const { user, logout } = useAuth0();

  const defaultFormValues: RegistrationFormValues = {
    email: user ? user.email ?? "" : "",
    firstName: user ? user.given_name ?? "" : "",
    lastName: user ? user.family_name ?? "" : "",
  };

  const onFormSubmit = async (
    values: RegistrationFormValues,
    formikHelpers: FormikHelpers<RegistrationFormValues>
  ) => {
    formikHelpers.setSubmitting(true);

    try {
      const newUser: User = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        approved: false,
        blocked: false,
      };

      await registerUser(newUser);

      window.location.reload();
    } catch (err) {
      formikHelpers.setFieldError("firstName", (err as Error).message);
      formikHelpers.setSubmitting(false);
    }
  };

  const onLogoutBtn = () => {
    logout();
  };

  return (
    <div className={styles.registrationScreen}>
      <div className={styles.container}>
        <Formik
          initialValues={defaultFormValues}
          onSubmit={onFormSubmit}
          validationSchema={RegistrationFormSchema}
        >
          {(props: FormikProps<RegistrationFormValues>) => {
            return (
              <Form className="form">
                <h1>Finish Creating your Account</h1>
                <div className="formRow">
                  <label htmlFor="firstName">First Name</label>
                  <Field
                    type="text"
                    name="firstName"
                    id="firstName"
                    autoComplete="firstName"
                  />
                  <span className="error">
                    <ErrorMessage name="firstName" />
                  </span>
                </div>
                <div className="formRow">
                  <label htmlFor="lastName">Last Name</label>
                  <Field
                    type="text"
                    name="lastName"
                    id="lastName"
                    autoComplete="lastName"
                  />
                  <span className="error">
                    <ErrorMessage name="lastName" />
                  </span>
                </div>
                <div className="formRow">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="email"
                    disabled={true}
                  />
                  <span className="error">
                    <ErrorMessage name="email" />
                  </span>
                </div>
                <div className="formRow buttons">
                  <button
                    type="submit"
                    className="button"
                    disabled={props.isSubmitting}
                  >
                    Complete Registration
                  </button>
                  <button
                    onClick={onLogoutBtn}
                    type="button"
                    className="button"
                  >
                    Logout
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
