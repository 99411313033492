import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { useSingleCategory } from "../hooks/useSingleCategory";
import { VideoCategory } from "../lib/definitions";
import { adminPutVideoCategory } from "../lib/http";

import styles from "./AdminCategoryEdit.module.css";

const validationSchema = yup.object({
  name: yup.string().required("Group name is required."),
});

interface CategoryInformationProps {
  category: VideoCategory;
}

export function AdminCategoryEdit() {
  const params = useParams();
  const navigate = useNavigate();

  const { isLoading, data } = useSingleCategory(params.videoCategoryId ?? "");

  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>
            <IconButton
              size="large"
              onClick={() => navigate("/admin/videos?tab=categories")}
            >
              <KeyboardBackspaceIcon fontSize="inherit" />
            </IconButton>
            Category: {data ? data.name : "...loading..."}
          </h1>
        </div>
      </header>
      <div className="page_content">
        <div className="inner">
          <div className={styles.groupEditor}>
            <div className={styles.fullcol}>
              {!isLoading && data && <CategoryInformation category={data} />}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

function CategoryInformation({ category }: CategoryInformationProps) {
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik<VideoCategory>({
    initialValues: category,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await adminPutVideoCategory(category.id!, values);
        enqueueSnackbar(`Category "${category.name}" saved successfully.`, {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      } catch (err) {
        enqueueSnackbar((err as Error).message, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      }
    },
  });

  return (
    <section className="section">
      <h2>Category Information</h2>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id="name"
              name="name"
              variant="standard"
              label="Category Name"
              fullWidth={true}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained">
              Save Category
            </Button>
          </Grid>
        </Grid>
      </form>
    </section>
  );
}
