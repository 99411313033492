import { useCallback, useMemo, useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import YouTube from "react-youtube";

import { useInterval } from "../hooks/useInterval";
import { getVideoID, Video } from "../lib/definitions";

import styles from "./VideoPlayer.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useHttp } from "../hooks/useHttp";

interface PlayerProps {
  reportView: () => void;
  reportProgress: (percent: number) => void;
}

interface VideoPlayerProps {
  video: Video;
  withAnalytics: boolean;
}

interface YouTubePlayerProps extends PlayerProps {
  videoID: string;
}

interface VimeoPlayerProps extends PlayerProps {
  videoID: string;
}

interface WSAnalyticsCallProgress {
  percentage: number;
  timestamp: number;
  viewID: string;
}

interface WSAnalyticsCall {
  token: string;
  oID: string;
  event: "view" | "progress" | "ended";
  videoID: string;
  progress?: WSAnalyticsCallProgress;
  viewID?: string;
}

interface WSAnalyticsResponse {
  error?: string;
  id?: string;
}

export function VideoPlayer({ video, withAnalytics }: VideoPlayerProps) {
  const [viewID, setViewID] = useState<string>("");
  const { getAccessTokenSilently } = useAuth0();
  const { organizationId } = useHttp();
  const videoID = getVideoID(video);

  const ws = useMemo(() => {
    if (!withAnalytics) {
      return null;
    }

    const webSocket = new WebSocket(
      `${process.env.REACT_APP_WS_URL}/api/v1/analytics/report`
    );
    webSocket.onmessage = (event) => {
      console.log(event);
      const response = JSON.parse(event.data) as WSAnalyticsResponse;
      if (response.error) {
        console.error(response.error);
      }

      if (response.id) {
        setViewID(response.id);
      }
    };
    return webSocket;
  }, [withAnalytics]);

  const sendView = useCallback(async () => {
    if (ws) {
      const viewCall: WSAnalyticsCall = {
        token: await getAccessTokenSilently(),
        oID: organizationId,
        event: "view",
        videoID: video.id!,
      };

      ws.send(JSON.stringify(viewCall));
    }
  }, [ws, video, organizationId, getAccessTokenSilently]);

  const sendProgress = useCallback(
    async (percent) => {
      if (ws) {
        const progressCall: WSAnalyticsCall = {
          token: await getAccessTokenSilently(),
          oID: organizationId,
          event: "progress",
          videoID: video.id!,
          progress: {
            percentage: percent,
            timestamp: Date.now() / 1000,
            viewID,
          },
        };
        ws.send(JSON.stringify(progressCall));
      }
    },
    [ws, video, viewID, organizationId, getAccessTokenSilently]
  );

  if (videoID) {
    if (video.source === "youtube") {
      return (
        <div className={styles.player}>
          <YouTubePlayer
            videoID={videoID}
            reportView={sendView}
            reportProgress={sendProgress}
          />
        </div>
      );
    }

    if (video.source === "vimeo") {
      return (
        <div className={styles.player}>
          <VimeoPlayer
            videoID={videoID}
            reportView={sendView}
            reportProgress={sendProgress}
          />
        </div>
      );
    }
  }

  return <div>Unsupported video source</div>;
}

function YouTubePlayer({
  videoID,
  reportProgress,
  reportView,
}: YouTubePlayerProps) {
  const [playerEvent, setPlayerEvent] = useState<any | null>(null);
  const [delay, setDelay] = useState<number | null>(null);

  useInterval(() => {
    const duration = playerEvent.target.getDuration();
    const currentTime = playerEvent.target.getCurrentTime();
    const percent = currentTime / duration;

    reportProgress(percent);
  }, delay);

  const startTimer = () => {
    setDelay(5000);
  };

  const endTimer = () => {
    const duration = playerEvent.target.getDuration();
    const currentTime = playerEvent.target.getCurrentTime();
    const percent = currentTime / duration;

    setDelay(null);
    reportProgress(percent);
  };

  return (
    <YouTube
      containerClassName={styles.container}
      videoId={videoID}
      opts={{
        width: "100%",
        height: "500",
        playerVars: {
          modestbranding: 1,
        },
      }}
      onReady={(e) => {
        reportView();
      }}
      onPlay={(e) => {
        setPlayerEvent(e);
        startTimer();
      }}
      onPause={() => {
        endTimer();
      }}
      onEnd={() => {
        endTimer();
      }}
    />
  );
}

function VimeoPlayer({
  videoID,
  reportProgress,
  reportView,
}: VimeoPlayerProps) {
  const [percent, setPercent] = useState<number>(0);
  const [delay, setDelay] = useState<number | null>(null);

  useInterval(() => {
    reportProgress(percent);
  }, delay);

  const startTimer = () => {
    setDelay(5000);
  };

  const endTimer = () => {
    setDelay(null);
    reportProgress(percent);
  };

  return (
    <Vimeo
      video={videoID}
      responsive
      onReady={(p) => {
        reportView();
      }}
      onPlay={(e) => {
        startTimer();
      }}
      onTimeUpdate={(e) => {
        setPercent(e.percent);
      }}
      onPause={(e) => {
        endTimer();
      }}
      onEnd={(e) => {
        endTimer();
      }}
    />
  );
}
