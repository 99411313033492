import { useQuery } from "react-query";
import { adminGetVideoCategories } from "../lib/http";
import { VideoCategory } from "../lib/definitions";

export function useVideoCategories() {
  return useQuery<VideoCategory[], Error>(
    "admin_videocategories",
    () => {
      return adminGetVideoCategories();
    },
    {
      enabled: true,
    }
  );
}
