import {useQuery} from "react-query";
import {OrgFile} from "../lib/definitions";
import {getUserFiles} from "../lib/http";

export function useUserFiles() {
    return useQuery<OrgFile[], Error>(
        ["user_files"], () => {
            return getUserFiles();
        },{
            enabled:true,
        }
    )
}