import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";

import { GlobalSearch } from "./GlobalSearch";

import { useAuth } from "../hooks/useAuth";

import styles from "./TopBar.module.css";
import logo from "../assets/logo.svg";
import { useUserMessages } from "../hooks/useUserMessages";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 36,
      height: 36,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export function TopBar() {
  const { user, isAdmin } = useAuth();
  const fullName = `${user.first_name} ${user.last_name}`;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openAccountMenu = Boolean(anchorEl);

  const { logout } = useAuth0();
  const navigate = useNavigate();

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAnchorEl(null);
  };

  const handleMessageClick = () => {
    navigate("/messages");
  };

  const { data } = useUserMessages();
  const messageCount = data ? data.unreadCount : 0;

  return (
    <div className={styles.topBar}>
      <div className="inner">
        <div className={styles.grid}>
          <h1 className="logo" onClick={() => navigate("/")}>
            <img src={logo} alt="VideoWatch" />
          </h1>
          <div className={styles.search}>
            <GlobalSearch />
          </div>
          <div className="user">
            <IconButton onClick={handleMessageClick}>
              <Badge badgeContent={messageCount} color="primary">
                <MailIcon color="action" />
              </Badge>
            </IconButton>
            <IconButton onClick={handleAvatarClick}>
              <Avatar {...stringAvatar(fullName)} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={openAccountMenu}
              onClose={handleAvatarClose}
              onClick={handleAvatarClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem disabled>Logged in as {fullName}</MenuItem>
              <Divider />
              {isAdmin && <AdminMenu />}
              <MenuItem
                color="error"
                onClick={() => {
                  logout({
                    returnTo: window.location.origin + "/login",
                  });
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}

function AdminMenu() {
  const navigate = useNavigate();

  return (
    <>
      <MenuItem
        onClick={() => {
          navigate("/admin/users");
        }}
      >
        Manage Users
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/admin/videos");
        }}
      >
        Manage Videos
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/admin/files");
        }}
      >
        Manage Files
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate("/admin/messages");
        }}
      >
        Manage Messages
      </MenuItem>
      <Divider />
    </>
  );
}
