import { useNavigate, useParams } from "react-router-dom";
import { useSingleFile } from "../hooks/useSingleFile";
import { IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { Entity, OrgFile } from "../lib/definitions";
import { useFormik } from "formik";
import { adminPutFile } from "../lib/http";

import { EntitySelect } from "../components/EntitySelect";

import styles from "./AdminFilesEdit.module.css";

interface FileInformationProps {
  file: OrgFile;
  groups: Entity[];
  videos: Entity[];
}

export function AdminFilesEdit() {
  const params = useParams();
  const navigate = useNavigate();

  const { isLoading, data } = useSingleFile(params.fileId ?? "");

  const fileGroups: Entity[] = data
    ? Object.keys(data.groups).map((g) => {
        return {
          name: data.groups[g],
          id: g,
          type: "Group",
        };
      })
    : [];

  const fileVideos: Entity[] = data
    ? Object.keys(data.videos).map((v) => {
        return {
          name: data.videos[v],
          id: v,
          type: "Video",
        };
      })
    : [];

  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>
            <IconButton size="large" onClick={() => navigate("/admin/files")}>
              <KeyboardBackspaceIcon fontSize="inherit" />
            </IconButton>
            File: {isLoading && <Skeleton variant="text" />}{" "}
            {!isLoading && data && <>{data.file_name}</>}
          </h1>
        </div>
      </header>
      <div className="page_content">
        <div className="inner">
          <div className={styles.fileEditor}>
            <div className={styles.fullcol}>
              {!isLoading && data && (
                <FileInformation
                  file={data}
                  groups={fileGroups}
                  videos={fileVideos}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

function FileInformation({ file, videos, groups }: FileInformationProps) {
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik<OrgFile>({
    initialValues: file,

    onSubmit: async (values) => {
      try {
        await adminPutFile(values.id!, values);
        enqueueSnackbar(`File "${file.file_name}" saved successfully.`, {
          variant: "success",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      } catch (err) {
        enqueueSnackbar((err as Error).message, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
        });
      }
    },
  });

  return (
    <div className="lcol">
      <h2>File Information</h2>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              value={formik.values.file_name}
              id="file_name"
              name="file_name"
              variant="standard"
              label="File Name"
              onChange={formik.handleChange}
              error={
                formik.touched.file_name && Boolean(formik.errors.file_name)
              }
              helperText={formik.touched.file_name && formik.errors.file_name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              disabled
              fullWidth
              value={formik.values.file_type}
              id="file_type"
              name="file_type"
              variant="standard"
              label="File Type"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              disabled
              fullWidth
              value={formik.values.size}
              id="size"
              name="size"
              variant="standard"
              label="Size"
            />
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined" className={styles.entitySelector}>
              <h4>Associated Videos</h4>
              <EntitySelect
                type="Video"
                initialSelection={videos}
                onSelectionChange={(entities) => {
                  const selectedVideos: any = {};
                  entities.forEach((e) => {
                    selectedVideos[e.id] = e.name;
                  });
                  formik.setFieldValue("videos", selectedVideos);
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined" className={styles.entitySelector}>
              <h4>Associated Groups</h4>
              <EntitySelect
                type="Group"
                initialSelection={groups}
                onSelectionChange={(entities) => {
                  const selectedGroups: any = {};
                  entities.forEach((e) => {
                    selectedGroups[e.id] = e.name;
                  });
                  formik.setFieldValue("groups", selectedGroups);
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained">
              Save File
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
