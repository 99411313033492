import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

interface HttpContextInterface {
  ready: boolean;
  organizationId: string;
}

export const HttpContext = createContext<HttpContextInterface>({
  ready: false,
  organizationId: "",
});

export const useHttp = () => {
  return useContext(HttpContext);
};

const HttpContextProvider: React.FC = ({ children }) => {
  const [clientReady, setClientReady] = useState<boolean>(false);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const initClient = useCallback(async () => {
    axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/v1`;

    console.log("setting up interceptors", isAuthenticated);
    axios.interceptors.request.use(
      async (config) => {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          });
          config.headers = {
            Authorization: `Bearer ${token}`,
            Organization: `d02db299-4a20-4b43-b456-a08fe25fbd99`,
          };
        }

        return config;
      },
      (err) => {
        return Promise.reject(err);
      }
    );

    setClientReady(true);
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (!isLoading) {
      initClient();
    }
  }, [isLoading, isAuthenticated, initClient]);

  return (
    <HttpContext.Provider
      value={{
        ready: clientReady,
        organizationId: "d02db299-4a20-4b43-b456-a08fe25fbd99",
      }}
    >
      {children}
    </HttpContext.Provider>
  );
};

export default HttpContextProvider;
