import { useNavigate } from "react-router-dom";
import { Fab, Grid, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { format } from "date-fns";
import { useState } from "react";
import { useQuery } from "react-query";
import { AdminMessageResponse, Message } from "../lib/definitions";
import { adminGetMessages } from "../lib/http";
import { useDebouncedEffect } from "../hooks/useDebouncedEffect";

function useMessages(currentPage: number, searchTerm: string) {
  return useQuery<AdminMessageResponse, Error>(
    [
      "admin_messages",
      {
        page: currentPage,
        term: searchTerm,
      },
    ],
    () => {
      return adminGetMessages(currentPage, searchTerm);
    },
    {
      enabled: true,
    }
  );
}

const columns: GridColDef[] = [
  { field: "title", headerName: "Title", width: 350, sortable: false },
  {
    field: "to",
    headerName: "To",
    width: 150,
    sortable: false,
    renderCell: (params: GridRenderCellParams<Message>) => {
      if (params.row.to_groups) {
        const numberOfGroups = Object.keys(params.row.to_groups).length;
        return `${numberOfGroups} Groups`;
      }

      return "---";
    },
  },
  {
    field: "send_at",
    headerName: "Send At",
    width: 230,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${format(params.value as Date, "MM/dd/yyyy - hh:mmaa")}
  `,
  },
  {
    field: "created_at",
    headerName: "Created At",
    width: 230,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) =>
      `${format(params.value as Date, "MM/dd/yyyy - hh:mmaa")}
  `,
  },
];

export function AdminMessages() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentSelection, setCurrentSelection] = useState<string[]>([]);

  const { isLoading, data, refetch } = useMessages(currentPage, searchTerm);

  const onNewMessageClick = async () => {
    navigate("/admin/messages/new");
  };
  const onDeleteClick = async () => {};

  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>Organization Messages</h1>
        </div>
      </header>
      <div className="page_content">
        <div className="inner">
          <Grid container spacing={2} style={{ marginBottom: 30 }}>
            <Grid item xs={4}>
              <Search
                label="Search for messages"
                onSearch={(term) => {
                  setCurrentSelection([]);
                  setCurrentPage(0);
                  setSearchTerm(term);
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <div className="toolbarContainer">
                <Fab
                  color="primary"
                  size="medium"
                  variant="extended"
                  onClick={onNewMessageClick}
                >
                  <AddIcon /> New Message
                </Fab>

                <Fab
                  color="default"
                  size="small"
                  disabled={currentSelection.length === 0}
                  onClick={onDeleteClick}
                >
                  <DeleteIcon />
                </Fab>
              </div>
            </Grid>
          </Grid>
          <div className="dataTableContainer">
            <DataGrid
              loading={isLoading}
              rows={data ? data.messages : []}
              columns={columns}
              pageSize={25}
              autoHeight={true}
              paginationMode={"server"}
              rowsPerPageOptions={[25]}
              disableColumnMenu={true}
              checkboxSelection
              disableSelectionOnClick
              rowCount={data ? data.total : 0}
              onPageChange={(page) => {
                setCurrentPage(page);
                refetch();
              }}
              onRowDoubleClick={(row) => {
                navigate(`/admin/messages/${row.id}`);
              }}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

interface SearchFieldProps {
  label: string;
  onSearch: (term: string) => void;
}

function Search({ label, onSearch }: SearchFieldProps) {
  const [value, setValue] = useState<string>("");

  useDebouncedEffect(() => onSearch(value), [value], 600);

  return (
    <TextField
      fullWidth
      label={label}
      variant="standard"
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
      }}
    />
  );
}
