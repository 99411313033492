import { useQuery } from "react-query";
import { SingleUserAnalytics } from "../lib/definitions";
import { adminGetOrgUserAnalytics } from "../lib/http";

export function useSingleUserAnalytics(id: string) {
  return useQuery<SingleUserAnalytics, Error>(
    [
      "user_analytics",
      {
        id,
      },
    ],
    () => {
      if (id === "new") {
        return {
          videos: [],
        };
      }

      return adminGetOrgUserAnalytics(id);
    },
    {
      enabled: true,
    }
  );
}
