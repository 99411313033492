import * as yup from "yup";
import { useFormik } from "formik";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import DateTimePicker from "@mui/lab/DateTimePicker";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { Video } from "../../lib/definitions";
import { adminPostVideo } from "../../lib/http";
import { EntitySelect } from "../EntitySelect";

import styles from "./DialogStyles.module.css";

interface NewVideoDialogProps {
  open: boolean;
  onClose: () => void;
}

const validationSchema = yup.object({
  title: yup.string().required("Video title is required."),
  source: yup.string().required("Video source type is required."),
  url: yup.string().required("Video source url is required."),
});

export function NewVideoDialog({ open, onClose }: NewVideoDialogProps) {
  const formik = useFormik<Video>({
    initialValues: {
      status: "published",
      title: "",
      source: "",
      url: "",
      thumbnail_url: "",
      categories: {},
      groups: {},
      files: {},
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await adminPostVideo(values);
        onFormClose();
      } catch (err) {
        formik.setFieldError("title", (err as Error).message);
        formik.setSubmitting(false);
      }
    },
  });

  const onFormClose = () => {
    formik.resetForm();
    onClose();
  };

  const onFormSubmit = () => {
    console.log(formik.values);
    formik.handleSubmit();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onBackdropClick={onClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>New Video</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                id="title"
                name="title"
                variant="standard"
                label="Video Title"
                fullWidth={true}
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                required
                select
                id="source"
                name="source"
                variant="standard"
                label="Source"
                fullWidth={true}
                value={formik.values.source}
                onChange={formik.handleChange}
                error={formik.touched.source && Boolean(formik.errors.source)}
                helperText={formik.touched.source && formik.errors.source}
              >
                <MenuItem key="youtube" value="youtube">
                  YouTube
                </MenuItem>
                <MenuItem key="vimeo" value="vimeo">
                  Vimeo
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <TextField
                required
                id="url"
                name="url"
                variant="standard"
                label="URL"
                fullWidth={true}
                value={formik.values.url}
                onChange={formik.handleChange}
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={formik.touched.url && formik.errors.url}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                id="status"
                name="status"
                variant="standard"
                label="Publish Status"
                fullWidth={true}
                value={formik.values.status}
                onChange={formik.handleChange}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
              >
                <MenuItem key="unpublished" value="unpublished">
                  Unpublished
                </MenuItem>
                <MenuItem key="published" value="published">
                  Published
                </MenuItem>
                <MenuItem key="scheduled" value="scheduled">
                  Scheduled
                </MenuItem>
              </TextField>
            </Grid>
            {formik.values.status === "scheduled" && (
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField
                        fullWidth={true}
                        name="published_at"
                        id="published_at"
                        variant="standard"
                        {...props}
                      />
                    )}
                    label="Published At Date"
                    value={formik.values.published_at}
                    onChange={(date) => {
                      formik.setFieldValue("published_at", date);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            <Grid item xs={12}>
              <Card variant="outlined" className={styles.entitySelector}>
                <h4>Categories</h4>
                <EntitySelect
                  type="Category"
                  initialSelection={[]}
                  onSelectionChange={(entities) => {
                    const selectedCategories: any = {};
                    entities.forEach((e) => {
                      selectedCategories[e.id] = e.name;
                    });
                    formik.setFieldValue("categories", selectedCategories);
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card variant="outlined" className={styles.entitySelector}>
                <h4>Groups</h4>
                <EntitySelect
                  type="Group"
                  initialSelection={[]}
                  onSelectionChange={(entities) => {
                    const selectedGroups: any = {};
                    entities.forEach((e) => {
                      selectedGroups[e.id] = e.name;
                    });
                    formik.setFieldValue("groups", selectedGroups);
                  }}
                />
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card variant="outlined" className={styles.entitySelector}>
                <h4>Files</h4>
                <EntitySelect
                  type="File"
                  initialSelection={[]}
                  onSelectionChange={(entities) => {
                    const selectedFiles: any = {};
                    entities.forEach((e) => {
                      selectedFiles[e.id] = e.name;
                    });
                    formik.setFieldValue("files", selectedFiles);
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={onFormClose}>
          Cancel
        </Button>
        <Button disabled={formik.isSubmitting} onClick={onFormSubmit}>
          Save Video
        </Button>
      </DialogActions>
    </Dialog>
  );
}
