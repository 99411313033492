import React from "react";

import { Outlet } from "react-router-dom";
import { TopBar } from "../components/TopBar";

export function Dashboard() {
  return (
    <div className="page">
      <TopBar />
      <Outlet />
    </div>
  );
}
