import { useEffect, useState } from "react";

import Autocomplete, {
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
  Avatar,
  CircularProgress,
  IconButton,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material";
import VideoIcon from "@mui/icons-material/VideoFileRounded";
import DeleteIcon from "@mui/icons-material/Delete";

import { Entity } from "../lib/definitions";
import { useGlobalSearch } from "../hooks/useGlobalSearch";
import { useDebouncedEffect } from "../hooks/useDebouncedEffect";
import { Link } from "react-router-dom";

interface EntitySelectProps {
  type: "File" | "Video" | "Group" | "Category";
  initialSelection: Entity[];
  onSelectionChange: (entities: Entity[]) => void;
}

export function EntitySelect({
  type,
  initialSelection,
  onSelectionChange,
}: EntitySelectProps) {
  const [selection, setSelection] = useState<Entity[]>(initialSelection);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [settledTerm, setSettledTerm] = useState<string>("");

  useDebouncedEffect(() => setSettledTerm(searchTerm), [searchTerm], 600);

  const { isLoading, data } = useGlobalSearch(settledTerm);

  const onSelection = (item: Entity, reason: AutocompleteChangeReason) => {
    if (reason === "selectOption") {
      if (
        !selection.find(
          (e) =>
            e.id === (item as Entity).id && e.type === (item as Entity).type
        )
      ) {
        setSelection([...selection, item as Entity]);
      }
    }
  };

  const onSelectionDelete = (entity: Entity) => {
    const index = selection.findIndex(
      (e) =>
        e.id === (entity as Entity).id && e.type === (entity as Entity).type
    );

    const updatedSelection = [...selection];
    updatedSelection.splice(index, 1);
    setSelection(updatedSelection);
  };

  useEffect(() => {
    onSelectionChange(selection);
  }, [selection]);

  const results = data?.filter((e) => e.type === type);

  return (
    <div>
      <Autocomplete
        id="entity_select"
        autoComplete
        includeInputInList
        filterSelectedOptions
        fullWidth={true}
        freeSolo
        loading={isLoading}
        filterOptions={(x) => x}
        options={results ? results : []}
        getOptionLabel={(option) => settledTerm}
        onChange={(event, item, reason) => {
          onSelection(item as Entity, reason);
        }}
        onInputChange={(event, newInputValue) => {
          setSearchTerm(newInputValue);
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Search for ${type.toLowerCase()}s...`}
            size="small"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <div className="selectedList">
        <List>
          {selection.length === 0 && (
            <ListItem>No {type.toLowerCase()}s selected</ListItem>
          )}
          {selection.map((e) => {
            let link = "";
            if (e.type === "Video") {
              link = `/video/${e.id}`;
            }

            if (e.type === "Group") {
              link = `/admin/users/group/${e.id}`;
            }

            if (e.type === "Category") {
              link = `/admin/videos/category/${e.id}`;
            }

            if (e.type === "File") {
              link = `/admin/files/${e.id}`;
            }
            return (
              <ListItem
                key={e.id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => onSelectionDelete(e)}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <VideoIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={e.name}
                  secondary={
                    <>
                      <Link to={link}>View {e.type}</Link>
                    </>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  );
}
