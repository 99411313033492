import * as yup from "yup";
import { useFormik } from "formik";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { adminPostGroup } from "../../lib/http";
import { Group } from "../../lib/definitions";

interface NewGroupDialogProps {
  open: boolean;
  onClose: () => void;
}

interface FormValues {
  name: string;
}

const validationSchema = yup.object({
  name: yup.string().required("Group name is required."),
});

export function NewGroupDialog({ open, onClose }: NewGroupDialogProps) {
  const formik = useFormik<FormValues>({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const newGroup: Group = {
          name: values.name,
        };
        await adminPostGroup(newGroup);
        onFormClose();
      } catch (err) {
        formik.setFieldError("name", (err as Error).message);
        formik.setSubmitting(false);
      }
    },
  });

  const onFormClose = () => {
    formik.resetForm();
    onClose();
  };

  const onFormSubmit = () => {
    formik.handleSubmit();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      onBackdropClick={onClose}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle>New Group</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                id="name"
                name="name"
                variant="standard"
                label="Name"
                fullWidth={true}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={onFormClose}>
          Cancel
        </Button>
        <Button disabled={formik.isSubmitting} onClick={onFormSubmit}>
          Save Group
        </Button>
      </DialogActions>
    </Dialog>
  );
}
