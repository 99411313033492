import { useQuery } from "react-query";
import { Entity } from "../lib/definitions";
import { getSearchResults } from "../lib/http";

export function useGlobalSearch(term: string) {
  return useQuery<Entity[], Error>(
    ["search_results", { term }],
    () => {
      return getSearchResults(term);
    },
    {
      enabled: true,
    }
  );
}
