import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebouncedEffect } from "../hooks/useDebouncedEffect";

import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useGlobalSearch } from "../hooks/useGlobalSearch";
import { Entity } from "../lib/definitions";
import { downloadFile } from "../lib/http";

export function GlobalSearch() {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [settledTerm, setSettledTerm] = useState<string>("");

  const { isLoading, data } = useGlobalSearch(settledTerm);

  useDebouncedEffect(() => setSettledTerm(searchTerm), [searchTerm], 600);

  return (
    <Autocomplete
      id="global_search"
      autoComplete
      includeInputInList
      filterSelectedOptions
      fullWidth={true}
      loading={isLoading}
      freeSolo
      options={data ?? []}
      style={{ margin: 0 }}
      filterOptions={(x) => x}
      getOptionLabel={(option) => settledTerm}
      onChange={(event, item: any, reason) => {
        if (reason === "selectOption") {
          if (item.id) {
            const entity = item as Entity;

            if (entity.type === "Video") {
              navigate(`/video/${item.id}`);
            }

            if (entity.type === "File") {
              downloadFile(entity.id);
            }
          }
        }
      }}
      onInputChange={(event, newInputValue) => {
        setSearchTerm(newInputValue);
      }}
      renderOption={(props, option) => {
        console.log(option);
        return (
          <li {...props} key={option.id!}>
            {option.thumbnail && (
              <div
                style={{
                  width: 100,
                  height: 75,
                  backgroundImage: `url(${option.thumbnail})`,
                  backgroundSize: "cover",
                  backgroundPosition: "top center",
                  marginRight: 20,
                }}
              />
            )}
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search for videos and files"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
