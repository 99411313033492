import { useQuery } from "react-query";
import { OrgFile } from "../lib/definitions";
import { adminGetFile } from "../lib/http";

export function useSingleFile(id: string) {
  return useQuery<OrgFile, Error>(
    ["file", { id }],
    () => {
      return adminGetFile(id);
    },
    {
      enabled: true,
    }
  );
}
