import { useState } from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import MailIcon from "@mui/icons-material/Mail";

import { useUserMessages } from "../hooks/useUserMessages";
import { Badge } from "@mui/material";
import { Message } from "../lib/definitions";

import styles from "./UserMessages.module.css";
import { format } from "date-fns";
import { markMessageRead } from "../lib/http";

export function UserMessages() {
  const [message, setMessage] = useState<Message | null>(null);

  const { isLoading, data, refetch } = useUserMessages();
  const messages = data?.messages ? data.messages : [];

  const onMessageClick = (msg: Message) => {
    if (message) {
      setMessage(null);
    } else {
      setMessage(msg);
      const isRead = data?.read.includes(msg.id!) ?? false;
      if (!isRead) {
        onReadMessage(msg.id!);
      }
    }
  };

  const onReadMessage = async (id: string) => {
    try {
      await markMessageRead(id);
      await refetch();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <main>
      <header className="page_header">
        <div className="inner">
          <h1>Messages</h1>
        </div>
      </header>
      <div className="page_content">
        <div className="inner">
          <div className={styles.messagesPane}>
            <div className={styles.list}>
              <List>
                {messages.map((msg) => {
                  const isRead = data?.read.includes(msg.id!) ?? false;
                  const isActive = message ? message.id! === msg.id! : false;
                  return (
                    <ListItemButton
                      selected={isActive}
                      key={msg.id!}
                      onClick={() => {
                        onMessageClick(msg);
                      }}
                    >
                      <ListItemAvatar>
                        <Badge
                          color="secondary"
                          invisible={isRead}
                          variant="dot"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Avatar>
                            <MailIcon />
                          </Avatar>
                        </Badge>
                      </ListItemAvatar>
                      <ListItemText
                        primary={msg.title}
                        secondary={format(
                          msg.send_at as Date,
                          "MM/dd/yyyy - hh:mmaa"
                        )}
                      />
                    </ListItemButton>
                  );
                })}
              </List>
            </div>
            <div className={styles.messages}>
              {message && (
                <div className={styles.selectedMessage}>
                  <header>
                    <h1>{message.title}</h1>
                    <div className={styles.date}>
                      Sent on{" "}
                      {format(message.send_at as Date, "MM/dd/yyyy - hh:mmaa")}
                    </div>
                  </header>
                  <div className="content">{message.message}</div>
                </div>
              )}
              {!message && (
                <div className={styles.empty}>
                  Select a message in the list on the left
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
