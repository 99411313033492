import { useQuery } from "react-query";
import { UserVideoResponse } from "../lib/definitions";
import { getUserVideos } from "../lib/http";

export function useUserVideos(
  currentPage: number,
  sortBy: string,
  category: string
) {
  return useQuery<UserVideoResponse, Error>(
    ["user_videos", { page: currentPage, sortBy, category }],
    () => {
      return getUserVideos(currentPage, sortBy, category);
    },
    { enabled: true }
  );
}
