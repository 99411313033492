import { OrgFile } from "../lib/definitions";
import { downloadFile } from "../lib/http";

import styles from "./FileListing.module.css";

interface FileListingProps {
  files: OrgFile[];
}

export function FileListing({ files }: FileListingProps) {
  return (
    <div className={styles.files}>
      <h1>Related Files</h1>
      <ul>
        {files.map((f) => {
          return (
            <li
              key={f.id!}
              onClick={() => {
                downloadFile(f.id!);
              }}
            >
              {f.file_name}
              {f.file_type}
            </li>
          );
        })}
        {files.length === 0 && (
          <li className={styles.empty}>No associated files</li>
        )}
      </ul>
    </div>
  );
}
