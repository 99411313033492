import { useQuery } from "react-query";
import { Video } from "../lib/definitions";
import { adminGetVideo } from "../lib/http";

export function useSingleVideo(id: string) {
  return useQuery<Video, Error>(
    ["video", { id }],
    () => {
      return adminGetVideo(id);
    },
    {
      enabled: true,
    }
  );
}
