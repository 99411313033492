import { useAuth0 } from "@auth0/auth0-react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Loading } from "../components/Loading";
import { RegistrationForm } from "../components/RegistrationForm";
import { UserMessage } from "../components/UserMessage";
import { User, UserStatus } from "../lib/definitions";
import { currentUser, validateUser } from "../lib/http";
import * as Sentry from "@sentry/react";
import { useHttp } from "./useHttp";

// TODO: Change the way this is done
const RoleUser = "fbf9e1a5-839a-4249-b311-242f009790a1";
const RoleUserManager = "c1fd6d99-32b9-457e-a075-7a0088b637f0";
const RoleVideoManager = "1be2d2b2-5029-44c5-9c68-d2e05b94f500";
const RoleFileManager = "1276b2f7-6eb0-4e1c-943f-8fc39838db82";
const RoleAdmin = "47a449b5-1fc1-4d91-84e1-8a03f5cbf93f";

interface AuthContextInterface {
  isLoggedIn: boolean;
  isAdmin: boolean;
  user: User;
}

const initialUser: User = {
  approved: false,
  blocked: false,
  email: "",
  first_name: "",
  last_name: "",
};

export const AuthContext = createContext<AuthContextInterface>({
  isLoggedIn: false,
  isAdmin: false,
  user: initialUser,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthContextProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User>(initialUser);
  const [userStatus, setUserStatus] = useState<UserStatus>("Unknown");
  const [userError, setUserError] = useState<Error | null>(null);

  const { ready } = useHttp();
  const { isLoading, isAuthenticated, user: auth0User } = useAuth0();

  const isAdmin = useCallback(() => {
    switch (user.role_id!) {
      case RoleUser:
        return false;
      case RoleFileManager:
      case RoleUserManager:
      case RoleVideoManager:
      case RoleAdmin:
        return true;
      default:
        return false;
    }
  }, [user]);

  const getCurrentUser = useCallback(async () => {
    try {
      const status = await validateUser();

      if (status !== "New") {
        const user = await currentUser();
        setUser(user);
      }

      setUserStatus(status as UserStatus);
    } catch (err: any) {
      setUserStatus("Error");
      setUserError(err as Error);
      Sentry.captureException(err, {
        user: {
          email: auth0User?.email,
          id: auth0User?.sub,
        },
        extra: {
          isAuthenticated: isAuthenticated,
          rawError: JSON.stringify(err),
          errorJson: err.toJSON(),
        },
      });
    }
  }, []);

  const isUserResolving = isAuthenticated && userStatus === "Unknown";

  useEffect(() => {
    if (isAuthenticated && ready) {
      getCurrentUser();
    }
  }, [isAuthenticated, getCurrentUser, ready]);

  if (isLoading || isUserResolving) {
    return <Loading />;
  }

  if (
    userStatus === "Error" ||
    userStatus === "Blocked" ||
    userStatus === "Approval"
  ) {
    return <UserMessage status={userStatus} error={userError ?? undefined} />;
  }

  if (userStatus === "New") {
    return <RegistrationForm />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn: isAuthenticated && userStatus === "OK",
        isAdmin: isAdmin(),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
